.secureMessage {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-right: 0!important;

  i {
    margin-right: 1rem;
    font-size: 2rem;
  }

  p {
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.creditcards {
  display: flex;
  flex-direction: row; 
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;

  img {
    @media (max-width: 640px) {
      max-width: 60px;
    }
  }
}

#card-container {
  display: flex;
  flex-direction: row; 
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-top: 1rem;

  img {
    @media (max-width: 640px) {
      max-width: 60px;
    }
  }
}

app-collect-payment {
  width: 100%;
}

#cardCollectionForm {
  .mat-form-field {
    margin: 0px;

    @media (max-width: 640px) {
      width: 100%;
    }
  }
}