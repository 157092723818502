app-hotel-pref {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
  }
  
  #arrivaldate {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;

    @media (max-width: 640px) {
      width: 100%;
    }

    h3 {
      font-size: 1.4rem;
    }

    ngb-datepicker {
      //padding: 2rem;
      width: 80%;
      margin-bottom: 1rem;

      @media (max-width: 640px) {
        width: 100%;
      }

      .ngb-dp-month {
        width: 100%;

        ngb-datepicker-month {

          .ngb-dp-week {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
          }
        }
      }
    }
  }

  #departuredate {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;

    @media (max-width: 640px) {
      width: 100%;
    }

    h3 {
      font-size: 1.4rem;
    }

    ngb-datepicker {
      //padding: 2rem;
      width: 80%;
      margin-bottom: 1rem;

      @media (max-width: 640px) {
        width: 100%;
      }

      .ngb-dp-month {
        width: 100%;

        ngb-datepicker-month {

          .ngb-dp-week {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
          }
        }
      }
    }
  }
}

.hotelinfo img {
  width: 100%;
  max-width: 625px;
}
