.ngb-dp-day{
  &.disabled{
    .outside{
    color: #6c757d !important;
    opacity: .5 !important;
    }
  }
  .outside{
    color: #212529 !important;
    opacity: 1 !important;
  }
}
