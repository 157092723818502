.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-badge-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-standard-button-toggle-text-font: Roboto, "Helvetica Neue", sans-serif;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-header-text-size: 15px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-toolbar-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-progress-bar-background {
  fill: #cbd0e9;
}

.mat-progress-bar-buffer {
  background-color: #cbd0e9;
}

.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3f51b5;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #3f51b5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-standard-button-toggle-text-font: Roboto, "Helvetica Neue", sans-serif;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-header-text-size: 15px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ff4081;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff4081;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff4081;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff4081;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ff4081;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

b,
strong {
  font-weight: bold !important;
}

.mailwarning strong {
  color: #900;
}

section {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.page {
  padding-top: 2rem;
}
@media (max-width: 640px) {
  .page {
    padding-top: 1rem;
  }
}

.pageTemplateContainer {
  width: 100%;
  height: auto;
  padding-bottom: 2rem;
}
.mat-expansion-panel .mat-expansion-panel-header {
  background-color: #081c4e !important;
  border-radius: 0;
  font: 400 1rem/1.4rem Roboto, "Helvetica Neue", sans-serif !important;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #fff !important;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
  color: #fff !important;
}
.mat-expansion-panel .mat-expansion-panel-content {
  font: 400 1rem/1.4rem Roboto, "Helvetica Neue", sans-serif !important;
}

.contentFullVerticalHeight {
  min-height: 90vh;
  height: auto;
  max-height: 100%;
  width: 100%;
}
.contentFullVerticalHeight.halfVerticalHeight {
  min-height: 50vh;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.5411764706);
}

.guestError .mat-expansion-panel-header {
  background: #AA4A44 !important;
}

.agreeCheckbox {
  width: 100%;
  height: auto;
}
.agreeCheckbox.checkboxList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.agreeCheckbox .checkboxFlexContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.agreeCheckbox .checkboxFlexContainer label {
  margin-right: 0.25rem;
  line-height: 1rem;
  width: 100%;
  min-width: 100%;
}
.agreeCheckbox .checkboxFlexContainer label .mat-checkbox-inner-container {
  margin-left: 0 !important;
}
.agreeCheckbox .checkboxFlexContainer .mat-checkbox {
  margin-right: 0.25rem;
  padding-right: 0 !important;
}
.agreeCheckbox .checkboxFlexContainer a {
  text-decoration: none !important;
}

.agreeCheckboxPac {
  width: 100%;
  height: auto;
}
.agreeCheckboxPac.checkboxList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.agreeCheckboxPac .checkboxFlexContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.agreeCheckboxPac .checkboxFlexContainer label {
  margin-right: 0.25rem;
  line-height: 1.5rem;
  width: 100%;
  min-width: 100%;
}
.agreeCheckboxPac .checkboxFlexContainer label .mat-checkbox-inner-container {
  margin-left: 0 !important;
}
.agreeCheckboxPac .checkboxFlexContainer .mat-checkbox {
  margin-right: 0.25rem;
  padding-right: 0 !important;
  line-height: 1rem;
  align-self: self-start;
  margin-top: 3px;
}
.agreeCheckboxPac .checkboxFlexContainer a {
  text-decoration: none !important;
}

.checkboxFlexContainer {
  display: flex;
}
.checkboxFlexContainer.checkboxList {
  flex-direction: column;
}
.checkboxFlexContainer label {
  margin-right: 0.25rem;
  line-height: 1rem;
  width: 100%;
  min-width: 100%;
}
.checkboxFlexContainer label .mat-checkbox-inner-container {
  margin-left: 0 !important;
}
.checkboxFlexContainer .questionLabel {
  margin-bottom: 1rem;
}
.checkboxFlexContainer .mat-checkbox {
  margin-right: 0.25rem;
  padding-right: 0 !important;
}
.checkboxFlexContainer a {
  text-decoration: none !important;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
    margin-bottom: 0.6rem;
  }
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  h2 {
    font-size: 1.4rem;
    margin-bottom: 0.6rem;
  }
}

#outer_container {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
}
#outer_container .program {
  border: 1px solid #fbc81b;
  padding: 20px;
  margin-bottom: 2rem;
  position: relative;
}
#outer_container .program .printButton {
  position: absolute;
  top: 2%;
  right: 2%;
}
#outer_container .program .printButton .mat-icon {
  margin-bottom: 0 !important;
}
@media (max-width: 640px) {
  #outer_container .mat-grid-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto !important;
  }
}
@media (max-width: 640px) {
  #outer_container .mat-grid-list div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
}
@media (max-width: 640px) {
  #outer_container .mat-grid-list div .mat-grid-tile {
    width: 32% !important;
    margin-right: 1rem;
    position: relative;
    top: 0 !important;
  }
  #outer_container .mat-grid-list div .mat-grid-tile:last-child {
    margin-right: 0;
  }
}
#outer_container .card {
  margin-bottom: 2rem;
  width: 100%;
}
#outer_container .card .card-header {
  background: #081c4e !important;
  color: #fff !important;
  font-size: 1.2rem !important;
  width: 100%;
}
#outer_container .card .card-header .card-title {
  margin-bottom: 0 !important;
  display: flex;
  align-items: flex-start;
  margin-left: 1rem;
}
#outer_container .card .card-header .card-title i {
  font-size: 0.4rem;
}
#outer_container .card .card-body {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
}
@media (max-width: 640px) {
  #outer_container .card .card-body {
    padding: 1.25rem 0.75rem;
  }
}
#outer_container .card .card-body label {
  white-space: normal;
}
#outer_container .card .card-body label .addressLabel {
  margin-bottom: 1rem;
}
#outer_container article {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  height: auto;
}
@media (max-width: 640px) {
  #outer_container article {
    padding: 10px 0px;
  }
}

.help-block {
  margin-bottom: 1rem;
}

.btn.btn-primary, .btn.btn-secondary {
  background: #0077cd !important;
  border: 1px solid #fff !important;
  color: #fff;
}
.btn.btn-primary:hover, .btn.btn-secondary:hover {
  background: #00599a !important;
  border-color: #00599a !important;
}
.btn.btn-outline-primary {
  border-color: #333;
  color: #333;
}
.btn.btn-outline-primary:hover {
  background: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
  color: #FFFFFF;
}

.button_group {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
}
@media (max-width: 640px) {
  .button_group {
    flex-direction: column-reverse;
    justify-content: center;
  }
}
@media (max-width: 640px) {
  .button_group .btn {
    margin-bottom: 1rem;
  }
}
.button_group .btn.closeButton {
  border-radius: 50% !important;
}
.button_group.profileContinue {
  padding-left: 0 !important;
}
.button_group.profileSelect {
  padding-left: 0 !important;
}
.button_group.profileConfirm {
  padding-left: 0 !important;
}

.multiSelect {
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 640px) {
  .multiSelect {
    flex-direction: column;
  }
}
.multiSelect mat-form-field {
  width: 30%;
}
@media (max-width: 640px) {
  .multiSelect mat-form-field {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .mat-form-field.twoLineSelect .mat-select-placeholder {
    overflow: visible !important;
    margin-top: 40px;
  }
}
@media (max-width: 640px) {
  .mat-form-field.longQuestionText input[type=text] {
    overflow: visible !important;
    margin-top: 30px;
  }
}
@media (max-width: 640px) {
  .mat-form-field.longQuestionText input[type=number] {
    overflow: visible !important;
    margin-top: 30px;
  }
}
@media (max-width: 640px) {
  .mat-form-field.longQuestionText textarea {
    overflow: visible !important;
    margin-top: 30px;
  }
}

app-date {
  width: 100%;
}
app-date .mat-form-field-infix {
  width: 100%;
}

.mat-form-field .mat-form-field-label {
  font-size: 1rem;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #4285f4;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #4285f4;
}

mat-radio-group {
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: flex-start;
  align-items: flex-start;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  mat-radio-group mat-radio-button.mat-radio-button {
    width: 100%;
  }
}
mat-radio-group mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: #333 !important;
}
mat-radio-group mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #333;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #333 !important;
}

.mat-radio-label-content,
.mat-checkbox-label {
  white-space: normal;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  overflow: hidden;
}

.stickyButton {
  position: fixed;
  top: 15%;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
}
.stickyButton .mat-raised-button {
  background-color: #fbc81b;
}

.text-white {
  color: #fff;
}

.fa-info-circle {
  color: #081c4e !important;
}

#acknowledgment {
  width: 100%;
}
#acknowledgment .travelLinksPrimaryAndGuest p a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p a:hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p a:not([href]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p a:not([href]):not([tabindex]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p u {
  text-decoration: none !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p u a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p u a:hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p u a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p u a:not([href]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p u a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest p u a:not([href]):not([tabindex]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest a:hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest a:not([href]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#acknowledgment .travelLinksPrimaryAndGuest a:not([href]):not([tabindex]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

#attendee .firstNameInput {
  width: 80%;
}
@media (max-width: 640px) {
  #attendee .firstNameInput {
    width: 100%;
  }
}
#attendee .lastNameInput {
  width: 80%;
}
@media (max-width: 640px) {
  #attendee .lastNameInput {
    width: 100%;
  }
}
#attendee .prefixInput {
  width: 15%;
  top: 6px;
}
@media (max-width: 1199px) {
  #attendee .prefixInput {
    top: 5px;
  }
}
@media (max-width: 640px) {
  #attendee .prefixInput {
    width: 100%;
    top: 0px;
  }
}
#attendee .suffixInput {
  width: 15%;
  top: 6px;
}
@media (max-width: 1199px) {
  #attendee .suffixInput {
    top: 5px;
  }
}
@media (max-width: 640px) {
  #attendee .suffixInput {
    width: 100%;
    top: 0px;
  }
}
#attendee app-phone {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#attendee app-phone fieldset {
  width: 100%;
  height: auto;
}
#attendee app-phone fieldset .md-form {
  width: 100%;
  height: auto;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
#attendee app-phone fieldset .md-form span.country {
  width: 30%;
  margin-right: 1em;
}
#attendee app-phone fieldset .md-form span.country input {
  width: 100%;
}
#attendee app-phone fieldset .md-form span.area {
  width: 30%;
  margin-right: 1em;
}
#attendee app-phone fieldset .md-form span.area input {
  width: 100%;
}
#attendee app-phone fieldset .md-form span.intphone {
  width: 30%;
  margin-right: 0;
}
#attendee app-phone fieldset .md-form span.intphone input {
  width: 100%;
}
#attendee app-phone fieldset .md-form div.ng-star-inserted {
  width: 100%;
  align-self: flex-end;
  padding-top: 10px;
}
#attendee .ng-star-inserted {
  width: 100%;
}
#attendee .btn.ng-star-inserted {
  width: auto;
}

.thq-error-message {
  display: none;
}

.form-complete .thq-error-message {
  display: block;
}

#cancellation i.fas.fa-asterisk.required {
  font-size: 6px;
  top: -10px;
  position: relative;
}

#guest {
  min-height: 100vh;
}
#guest .add-guest {
  margin-bottom: 1rem;
  background: #222 !important;
  border: #222 !important;
}
#guest .add-guest:hover {
  background: #090909 !important;
  border: #090909 !important;
}

.nameFields {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.nameFields .firstNameInput {
  width: 80%;
}
@media (max-width: 640px) {
  .nameFields .firstNameInput {
    width: 100%;
  }
}
.nameFields .lastNameInput {
  width: 80%;
}
@media (max-width: 640px) {
  .nameFields .lastNameInput {
    width: 100%;
  }
}
.nameFields .prefixInput {
  width: 15%;
  top: 6px;
}
@media (max-width: 1199px) {
  .nameFields .prefixInput {
    top: 5px;
  }
}
@media (max-width: 640px) {
  .nameFields .prefixInput {
    width: 100%;
    top: 0px;
  }
}
.nameFields .suffixInput {
  width: 15%;
  top: 6px;
}
@media (max-width: 1199px) {
  .nameFields .suffixInput {
    top: 5px;
  }
}
@media (max-width: 640px) {
  .nameFields .suffixInput {
    width: 100%;
    top: 0px;
  }
}

.mat-expansion-panel .mat-expansion-panel-header {
  background-color: #ccc;
  border-radius: 0;
}

mat-accordion {
  display: block;
}

/* You can add global styles to this file, and also import other style files */
app-guest-travel {
  width: 100%;
}
app-guest-travel .travelLinksPrimaryAndGuest p a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p u {
  text-decoration: none !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p u a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p u a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p u a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p u a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p u a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
app-guest-travel .travelLinksPrimaryAndGuest p u a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

app-hotel-pref {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 640px) {
  app-hotel-pref {
    flex-direction: column;
    justify-content: center;
  }
}
app-hotel-pref #arrivaldate {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  app-hotel-pref #arrivaldate {
    width: 100%;
  }
}
app-hotel-pref #arrivaldate h3 {
  font-size: 1.4rem;
}
app-hotel-pref #arrivaldate ngb-datepicker {
  width: 80%;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  app-hotel-pref #arrivaldate ngb-datepicker {
    width: 100%;
  }
}
app-hotel-pref #arrivaldate ngb-datepicker .ngb-dp-month {
  width: 100%;
}
app-hotel-pref #arrivaldate ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
app-hotel-pref #departuredate {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  app-hotel-pref #departuredate {
    width: 100%;
  }
}
app-hotel-pref #departuredate h3 {
  font-size: 1.4rem;
}
app-hotel-pref #departuredate ngb-datepicker {
  width: 80%;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  app-hotel-pref #departuredate ngb-datepicker {
    width: 100%;
  }
}
app-hotel-pref #departuredate ngb-datepicker .ngb-dp-month {
  width: 100%;
}
app-hotel-pref #departuredate ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.hotelinfo img {
  width: 100%;
  max-width: 625px;
}

.hotelTable {
  font-family: "Noto Sans JP", sans-serif !important;
}
.hotelTable thead th {
  color: #555;
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  border: 0px solid transparent !important;
  padding: 1rem 0.5rem;
}
.hotelTable thead th:first-child {
  background: #fff !important;
}
.hotelTable thead th.eventDates {
  background-color: #efefef !important;
  border-left: 2px solid #999 !important;
  border-right: 2px solid #999 !important;
  border-top: 2px solid #999 !important;
  color: #333;
  font-weight: 600;
}
.hotelTable thead th.extension {
  background-color: #d4d4d4 !important;
  border-left: 2px solid #999 !important;
  border-right: 2px solid #999 !important;
  border-top: 2px solid #999 !important;
}
.hotelTable tbody {
  width: 100%;
  height: 100px;
  border-top: none;
}
.hotelTable tbody tr th {
  background: #045ba8;
  color: #fff;
  min-width: 60px !important;
  max-width: 60px !important;
  text-align: center;
  border-right: 1px solid #777;
  font-size: 1.2rem;
  font-weight: 300;
  padding: 0.5rem;
}
.hotelTable tbody tr th:first-child {
  background: #fff;
  min-width: 160px !important;
  color: #333;
  border-top: 1px solid transparent;
  vertical-align: middle;
  font-weight: bold;
  text-align: left;
  text-transform: capitalize;
}
.hotelTable tbody tr th.hotelDetails h2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.hotelTable tbody tr th.hotelDetails p a {
  font-size: 1rem;
  color: #0076A0;
  font-weight: 400;
  text-decoration: underline;
  display: none !important;
}
.hotelTable tbody tr th.hotelDetails p a:hover {
  color: #002b3a;
  text-decoration: none;
}
.hotelTable tbody tr th span.date {
  width: 100%;
  height: auto;
  float: left;
  clear: left;
  margin-bottom: 0px;
  font-size: 0.8em;
  font-weight: 400;
  font-family: "Roboto Condensed", sans-serif !important;
}
.hotelTable tbody tr th span.day {
  width: 100%;
  height: auto;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif !important;
  float: left;
  clear: left;
  font-size: 1.2em;
  margin-bottom: -0.5rem;
}
.hotelTable tbody tr td {
  border-top: none;
  border-right: 1px solid #777;
  border-bottom: 1px solid #777;
  min-width: 45px;
  max-width: 45px;
  height: 70px;
  background: #ebebeb;
}
.hotelTable tbody tr td.prepost {
  background-color: #d4d4d4;
}
.hotelTable tbody tr td:first-child {
  background: #fff;
}
.hotelTable tbody tr td .hotel_desc {
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Noto Sans JP", sans-serif !important;
}
.hotelTable tbody + tbody {
  border-top: none;
}

#invitation {
  min-height: 100vh;
}

/* You can add global styles to this file, and also import other style files */
#login a {
  color: #0069d9;
  text-decoration: underline;
}
#login a:hover {
  text-decoration: none;
}
#login .loginFlexContainer {
  flex-direction: row;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #login .loginFlexContainer {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #login .loginFlexContainer {
    flex-direction: column;
  }
}
#login .loginFlexContainer .login-info {
  width: 48%;
  margin-bottom: 1rem;
  padding-right: 1rem;
}
@media (max-width: 991px) {
  #login .loginFlexContainer .login-info {
    width: 100%;
  }
}
#login .loginFlexContainer .login-forms {
  width: 48%;
  margin-bottom: 1rem;
}
@media (max-width: 991px) {
  #login .loginFlexContainer .login-forms {
    width: 100%;
  }
}
#login .panel .panel-heading {
  background: #222;
  color: #fff;
}
#login .panel .panel-heading .panel-title {
  font-weight: 200;
}
#login .panel .panel-body .form-group i {
  font-size: 1.5rem;
  color: #333;
}
#login .button_group {
  width: 100%;
  /*flex-direction: row-reverse;*/
  justify-content: space-between;
}
@media (max-width: 640px) {
  #login .button_group {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  #login .button_group .inputSubmit {
    margin-bottom: 1rem;
    width: 100%;
  }
}
@media (max-width: 480px) {
  #login .button_group .back {
    width: 100%;
  }
}
@media (max-width: 480px) {
  #login .button_group input[type=submit].forward {
    width: 100%;
    margin-bottom: 1rem;
  }
}
#login .button_group input[type=submit].forward:hover {
  background: #666;
}
#login .helpiconInTextbox {
  float: right;
  position: absolute;
  left: 97%;
}

.secureMessage {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-right: 0 !important;
}
.secureMessage i {
  margin-right: 1rem;
  font-size: 2rem;
}
.secureMessage p {
  text-transform: uppercase;
  margin-bottom: 0;
}

.creditcards {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  .creditcards img {
    max-width: 60px;
  }
}

#card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-top: 1rem;
}
@media (max-width: 640px) {
  #card-container img {
    max-width: 60px;
  }
}

app-collect-payment {
  width: 100%;
}

#cardCollectionForm .mat-form-field {
  margin: 0px;
}
@media (max-width: 640px) {
  #cardCollectionForm .mat-form-field {
    width: 100%;
  }
}

#summary .badge {
  width: auto;
  height: auto;
  border: 1px solid #333;
  border-radius: 10px;
  padding: 2rem 4rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}
#summary form {
  margin-bottom: 1rem;
}
#summary .emptybody {
  margin: 0.4rem 0;
  background-color: #333 !important;
}
#summary .emptybody .card-title {
  margin: 0px;
  height: 100% !important;
}
#summary .card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
#summary .card .card-title {
  width: 100%;
  height: auto;
  color: #fff;
  padding: 0.6rem 1.25rem;
  border-radius: 4px 4px 0px 0px;
  background-color: #333 !important;
}
#summary .card .card-title .summaryHeaderAndPrint {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#summary .card .card-title .summaryHeaderAndPrint h1 {
  margin-bottom: 0;
}
#summary .card .card-body h2 {
  font-size: 1.4rem;
}
#summary .mat-expansion-panel-body {
  padding: 16px 24px;
}
#summary .edit-link {
  float: right;
  font-size: 0.8em;
  /*text-decoration: none;*/
}
#summary section {
  border: none !important;
}
#summary section.border {
  border: 1px solid #ddd !important;
}
#summary section.addMarginBottom {
  margin-bottom: 25px;
}
#summary a {
  text-decoration: none !important;
}
#summary a.responsibilityLink {
  color: #333 !important;
  text-decoration: underline !important;
}
#summary a.responsibilityLink:hover {
  color: #a6a6a6 !important;
  text-decoration: none !important;
}
#summary a.print {
  margin: -5px 0px 0px 10px;
  height: 10px;
  width: 10px;
  font-size: 1.5em;
}
#summary a.print i.fa.fa-print {
  width: 10px;
  height: auto;
  padding: 0;
  margin: 0;
  float: right;
  clear: none;
  left: 25px;
  position: relative;
}
#summary a.printcomplete {
  margin: 0 55px 0 0;
  font-size: 1em;
  height: 10px;
  width: 10px;
}
@media all and (width: 360px) {
  #summary a.printcomplete {
    position: relative;
    bottom: 27px;
    left: 13px;
  }
}
#summary a.printcomplete i.fa.fa-print {
  width: 10px;
  height: auto;
  padding: 0;
  margin: 0;
  float: right;
  clear: none;
  left: 25px;
  position: relative;
}
#summary .mat-expansion-panel {
  margin-bottom: 1rem;
}
#summary .mat-expansion-panel .mat-expansion-panel-body app-badge .summaryHeader,
#summary .mat-expansion-panel .mat-expansion-panel-body app-summary-display .summaryHeader,
#summary .mat-expansion-panel .mat-expansion-panel-body appaddresssummary .summaryHeader,
#summary .mat-expansion-panel .mat-expansion-panel-body app-phone-summary .summaryHeader,
#summary .mat-expansion-panel .mat-expansion-panel-body .ng-star-inserted .summaryHeader,
#summary .mat-expansion-panel .mat-expansion-panel-body .guestConsiderations .summaryHeader,
#summary .mat-expansion-panel .mat-expansion-panel-body .mainAttendeeConsiderations .summaryHeader {
  font-size: 1.2rem;
  margin: 1rem 0;
}
#summary .mat-expansion-panel .mat-expansion-panel-body app-badge .form-summary,
#summary .mat-expansion-panel .mat-expansion-panel-body app-summary-display .form-summary,
#summary .mat-expansion-panel .mat-expansion-panel-body appaddresssummary .form-summary,
#summary .mat-expansion-panel .mat-expansion-panel-body app-phone-summary .form-summary,
#summary .mat-expansion-panel .mat-expansion-panel-body .ng-star-inserted .form-summary,
#summary .mat-expansion-panel .mat-expansion-panel-body .guestConsiderations .form-summary,
#summary .mat-expansion-panel .mat-expansion-panel-body .mainAttendeeConsiderations .form-summary {
  padding: 1rem 1rem;
  background: #efefef;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #efefef;
  border-radius: 0.5rem;
}
#summary .mat-expansion-panel .mat-expansion-panel-body app-badge .form-summary:last-child,
#summary .mat-expansion-panel .mat-expansion-panel-body app-summary-display .form-summary:last-child,
#summary .mat-expansion-panel .mat-expansion-panel-body appaddresssummary .form-summary:last-child,
#summary .mat-expansion-panel .mat-expansion-panel-body app-phone-summary .form-summary:last-child,
#summary .mat-expansion-panel .mat-expansion-panel-body .ng-star-inserted .form-summary:last-child,
#summary .mat-expansion-panel .mat-expansion-panel-body .guestConsiderations .form-summary:last-child,
#summary .mat-expansion-panel .mat-expansion-panel-body .mainAttendeeConsiderations .form-summary:last-child {
  border-bottom: none;
}
#summary .mat-expansion-panel .mat-expansion-panel-body .form-summary {
  padding: 1rem 1rem;
  background: #efefef;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #efefef;
  border-radius: 0.5rem;
}
#summary .mat-expansion-panel .mat-expansion-panel-body .form-summary:last-child {
  border-bottom: none;
}
#summary uib-accordion h2 {
  position: relative;
  left: 5px;
}
#summary uib-accordion h2.notCollapsable {
  left: 0;
}
#summary uib-accordion h2.active:before {
  content: ">";
  font-style: normal;
  text-decoration: inherit;
  color: #444;
  font-size: 18px;
  position: absolute;
  top: 10px;
  left: -10px;
}
#summary uib-accordion h2.notActive:before {
  content: "^";
  font-style: normal;
  text-decoration: inherit;
  color: #444;
  font-size: 18px;
  position: absolute;
  top: 10px;
  left: -10px;
  margin-right: 10px;
}
#summary label {
  white-space: normal !important;
}
#summary .nametag {
  border-radius: 30px;
  border-width: 2px;
  border-style: solid;
  display: block;
  margin: 20px;
  padding: 50px 30px 30px;
  background-color: white;
  color: black;
  max-width: 80%;
  width: 25em;
  height: 12em;
  text-align: center;
}
#summary .nametag div {
  font-weight: bold;
  margin-bottom: 5px;
}
#summary .ownair-summary .ownair {
  cursor: auto;
}
#summary .ownair-summary .ownair:hover {
  transition: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#summary h2.addPaddingRight {
  padding-right: 50px;
}
#summary textarea {
  margin-bottom: 10px;
}
#summary .form-summary {
  overflow: hidden;
}
#summary .form-summary label {
  float: left;
  padding: 5px 0;
  margin: 0;
  font-weight: bold;
}
#summary .form-summary .form-summary-item {
  position: relative;
  float: left;
  padding: 5px 0;
  width: 100%;
}
#summary .form-summary .form-summary-item .form-summary-line {
  width: 100%;
  display: block;
}
@media all and (width: 360px) {
  #summary .pIconH1 {
    font-size: 1.85em;
  }
}
#summary .mat-expansion-panel-header-title {
  display: block;
}
#summary .summaryLinks .agreeCheckbox {
  margin-bottom: 0.5rem;
}
#summary .summaryLinks p a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks p a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks p a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks p a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks p a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks p a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks p u {
  text-decoration: none !important;
}
#summary .summaryLinks p u a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks p u a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks p u a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks p u a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks p u a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks p u a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .summaryLinks a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .summaryLinks a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .checkboxContainer a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .checkboxContainer a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .checkboxContainer a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .checkboxContainer a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#summary .checkboxContainer a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#summary .checkboxContainer a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

/* You can add global styles to this file, and also import other style files */
#travel h3 {
  font-size: 1.125rem !important;
  margin: 1rem auto;
}
#travel ngb-datepicker {
  width: 80%;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  #travel ngb-datepicker {
    width: 100%;
  }
}
#travel ngb-datepicker .ngb-dp-month {
  width: 100%;
}
#travel ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
#travel .travelLinksPrimaryAndGuest p a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p u {
  text-decoration: none !important;
}
#travel .travelLinksPrimaryAndGuest p u a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p u a:hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p u a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p u a:not([href]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p u a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
#travel .travelLinksPrimaryAndGuest p u a:not([href]):not([tabindex]):hover {
  color: #002b3a !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

#travel a {
  text-decoration: underline;
  color: #0076A0;
}

/* You can add global styles to this file, and also import other style files */
#profile {
  min-height: 60vh;
  height: auto;
}
#profile .card.profileCard {
  max-width: 700px;
  margin: 0 auto 2rem !important;
}
#profile .card.profileCard .profileInstructions {
  margin-bottom: 1rem;
}
#profile .card.profileCard section,
#profile .card.profileCard article,
#profile .card.profileCard form,
#profile .card.profileCard app-profile-login {
  width: 100%;
  height: auto;
}
#profile .card.profileCard section .profileBody,
#profile .card.profileCard article .profileBody,
#profile .card.profileCard form .profileBody,
#profile .card.profileCard app-profile-login .profileBody {
  padding: 2rem;
}
#profile .profileAll .button_group {
  padding-left: 20px;
}
#profile .profileAll .button_group.profileNew {
  padding-left: 0px !important;
  margin-bottom: 0;
}

.profileEdit #profile .card.profileCard {
  max-width: 100% !important;
}

.modifyProfile .card, .modifyProfile .accordion-item {
  background: #F0F0F0;
}
.modifyProfile .card .card-title, .modifyProfile .card .accordion-header, .modifyProfile .accordion-item .card-title, .modifyProfile .accordion-item .accordion-header {
  background: #D8D8D8;
}
.modifyProfile .card h2, .modifyProfile .accordion-item h2 {
  margin-top: 1rem;
  background: #D8D8D8;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}
.modifyProfile .card h3, .modifyProfile .accordion-item h3 {
  color: #555;
  margin: 1rem 0;
}
.modifyProfile .addGuest {
  margin-bottom: 2rem !important;
}
.modifyProfile .addRemoveButton {
  display: flex;
  justify-content: flex-end;
}

.phoneflex {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addRemoveButton .btn {
  margin-bottom: 1rem;
}

.topNote {
  width: 100%;
  height: auto;
  padding: 2rem;
  font-size: 1.6rem;
}

ngb-accordion.profileNavigation {
  margin: 1rem 0;
}
ngb-accordion.profileNavigation .card, ngb-accordion.profileNavigation .accordion-item {
  margin-bottom: 0 !important;
}
ngb-accordion.profileNavigation .card:first-child .card-header, ngb-accordion.profileNavigation .card:first-child .accordion-header, ngb-accordion.profileNavigation .accordion-item:first-child .card-header, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-header {
  padding-left: 0;
  background: #333 !important;
}
ngb-accordion.profileNavigation .card:first-child .card-header:hover, ngb-accordion.profileNavigation .card:first-child .accordion-header:hover, ngb-accordion.profileNavigation .accordion-item:first-child .card-header:hover, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-header:hover {
  background: #333 !important;
}
ngb-accordion.profileNavigation .card:first-child .card-header .btn, ngb-accordion.profileNavigation .card:first-child .card-header .accordion-button, ngb-accordion.profileNavigation .card:first-child .accordion-header .btn, ngb-accordion.profileNavigation .card:first-child .accordion-header .accordion-button, ngb-accordion.profileNavigation .accordion-item:first-child .card-header .btn, ngb-accordion.profileNavigation .accordion-item:first-child .card-header .accordion-button, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-header .btn, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-header .accordion-button {
  color: #fff;
  font-weight: 500;
  font-size: 1.4rem;
  text-decoration: none;
}
ngb-accordion.profileNavigation .card:first-child .card-body, ngb-accordion.profileNavigation .card:first-child .accordion-body, ngb-accordion.profileNavigation .accordion-item:first-child .card-body, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-body {
  padding: 16px 24px 16px;
}
ngb-accordion.profileNavigation .card:first-child .card-body ul, ngb-accordion.profileNavigation .card:first-child .accordion-body ul, ngb-accordion.profileNavigation .accordion-item:first-child .card-body ul, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-body ul {
  padding-left: 0;
}
ngb-accordion.profileNavigation .card:first-child .card-body ul li, ngb-accordion.profileNavigation .card:first-child .accordion-body ul li, ngb-accordion.profileNavigation .accordion-item:first-child .card-body ul li, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-body ul li {
  list-style-type: none;
  margin-bottom: 0.8rem;
  line-height: 1.2rem;
  font-weight: 500;
}
ngb-accordion.profileNavigation .card:first-child .card-body ul li a:hover, ngb-accordion.profileNavigation .card:first-child .accordion-body ul li a:hover, ngb-accordion.profileNavigation .accordion-item:first-child .card-body ul li a:hover, ngb-accordion.profileNavigation .accordion-item:first-child .accordion-body ul li a:hover {
  color: rgb(28, 38, 104);
}
ngb-accordion.profileNavigation .card .card-header, ngb-accordion.profileNavigation .card .accordion-header, ngb-accordion.profileNavigation .accordion-item .card-header, ngb-accordion.profileNavigation .accordion-item .accordion-header {
  background: #999 !important;
  padding: 0;
  padding-left: 1rem;
}
ngb-accordion.profileNavigation .card .card-header:hover, ngb-accordion.profileNavigation .card .accordion-header:hover, ngb-accordion.profileNavigation .accordion-item .card-header:hover, ngb-accordion.profileNavigation .accordion-item .accordion-header:hover {
  background: #999 !important;
}
ngb-accordion.profileNavigation .card .card-header#admin-header, ngb-accordion.profileNavigation .card .accordion-header#admin-header, ngb-accordion.profileNavigation .accordion-item .card-header#admin-header, ngb-accordion.profileNavigation .accordion-item .accordion-header#admin-header {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  background: #333 !important;
}
ngb-accordion.profileNavigation .card .card-header#admin-header:hover, ngb-accordion.profileNavigation .card .accordion-header#admin-header:hover, ngb-accordion.profileNavigation .accordion-item .card-header#admin-header:hover, ngb-accordion.profileNavigation .accordion-item .accordion-header#admin-header:hover {
  background: #333 !important;
}
ngb-accordion.profileNavigation .card .card-header#admin-header .btn, ngb-accordion.profileNavigation .card .card-header#admin-header .accordion-button, ngb-accordion.profileNavigation .card .accordion-header#admin-header .btn, ngb-accordion.profileNavigation .card .accordion-header#admin-header .accordion-button, ngb-accordion.profileNavigation .accordion-item .card-header#admin-header .btn, ngb-accordion.profileNavigation .accordion-item .card-header#admin-header .accordion-button, ngb-accordion.profileNavigation .accordion-item .accordion-header#admin-header .btn, ngb-accordion.profileNavigation .accordion-item .accordion-header#admin-header .accordion-button {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
ngb-accordion.profileNavigation .card .card-header#admin-header + div#admin, ngb-accordion.profileNavigation .card .accordion-header#admin-header + div#admin, ngb-accordion.profileNavigation .accordion-item .card-header#admin-header + div#admin, ngb-accordion.profileNavigation .accordion-item .accordion-header#admin-header + div#admin {
  display: none !important;
}
ngb-accordion.profileNavigation .card .card-header#addguest-header, ngb-accordion.profileNavigation .card .accordion-header#addguest-header, ngb-accordion.profileNavigation .accordion-item .card-header#addguest-header, ngb-accordion.profileNavigation .accordion-item .accordion-header#addguest-header {
  padding: 0 !important;
}
ngb-accordion.profileNavigation .card .card-header#addguest-header + div#addguest, ngb-accordion.profileNavigation .card .accordion-header#addguest-header + div#addguest, ngb-accordion.profileNavigation .accordion-item .card-header#addguest-header + div#addguest, ngb-accordion.profileNavigation .accordion-item .accordion-header#addguest-header + div#addguest {
  display: none !important;
}
ngb-accordion.profileNavigation .card .card-header#saveprofile-header, ngb-accordion.profileNavigation .card .accordion-header#saveprofile-header, ngb-accordion.profileNavigation .accordion-item .card-header#saveprofile-header, ngb-accordion.profileNavigation .accordion-item .accordion-header#saveprofile-header {
  padding: 0 !important;
}
ngb-accordion.profileNavigation .card .card-header#saveprofile-header + div#saveprofile, ngb-accordion.profileNavigation .card .accordion-header#saveprofile-header + div#saveprofile, ngb-accordion.profileNavigation .accordion-item .card-header#saveprofile-header + div#saveprofile, ngb-accordion.profileNavigation .accordion-item .accordion-header#saveprofile-header + div#saveprofile {
  display: none !important;
}
ngb-accordion.profileNavigation .card .card-header .btn, ngb-accordion.profileNavigation .card .card-header .accordion-button, ngb-accordion.profileNavigation .card .accordion-header .btn, ngb-accordion.profileNavigation .card .accordion-header .accordion-button, ngb-accordion.profileNavigation .accordion-item .card-header .btn, ngb-accordion.profileNavigation .accordion-item .card-header .accordion-button, ngb-accordion.profileNavigation .accordion-item .accordion-header .btn, ngb-accordion.profileNavigation .accordion-item .accordion-header .accordion-button {
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
  text-decoration: none;
  width: 100%;
  text-align: left;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  background: transparent;
  border: none !important;
}
ngb-accordion.profileNavigation .card .card-header .btn[aria-expanded=true] i, ngb-accordion.profileNavigation .card .card-header .accordion-button[aria-expanded=true] i, ngb-accordion.profileNavigation .card .accordion-header .btn[aria-expanded=true] i, ngb-accordion.profileNavigation .card .accordion-header .accordion-button[aria-expanded=true] i, ngb-accordion.profileNavigation .accordion-item .card-header .btn[aria-expanded=true] i, ngb-accordion.profileNavigation .accordion-item .card-header .accordion-button[aria-expanded=true] i, ngb-accordion.profileNavigation .accordion-item .accordion-header .btn[aria-expanded=true] i, ngb-accordion.profileNavigation .accordion-item .accordion-header .accordion-button[aria-expanded=true] i {
  transform: rotate(-180deg);
}
ngb-accordion.profileNavigation .card .card-header .btn.btn-success.customSuccess, ngb-accordion.profileNavigation .card .card-header .accordion-button.btn-success.customSuccess, ngb-accordion.profileNavigation .card .accordion-header .btn.btn-success.customSuccess, ngb-accordion.profileNavigation .card .accordion-header .accordion-button.btn-success.customSuccess, ngb-accordion.profileNavigation .accordion-item .card-header .btn.btn-success.customSuccess, ngb-accordion.profileNavigation .accordion-item .card-header .accordion-button.btn-success.customSuccess, ngb-accordion.profileNavigation .accordion-item .accordion-header .btn.btn-success.customSuccess, ngb-accordion.profileNavigation .accordion-item .accordion-header .accordion-button.btn-success.customSuccess {
  background: #FAD141 !important;
  border-color: #FAD141 !important;
}
ngb-accordion.profileNavigation .card .card-header .btn.btn-danger.customDanger, ngb-accordion.profileNavigation .card .card-header .accordion-button.btn-danger.customDanger, ngb-accordion.profileNavigation .card .accordion-header .btn.btn-danger.customDanger, ngb-accordion.profileNavigation .card .accordion-header .accordion-button.btn-danger.customDanger, ngb-accordion.profileNavigation .accordion-item .card-header .btn.btn-danger.customDanger, ngb-accordion.profileNavigation .accordion-item .card-header .accordion-button.btn-danger.customDanger, ngb-accordion.profileNavigation .accordion-item .accordion-header .btn.btn-danger.customDanger, ngb-accordion.profileNavigation .accordion-item .accordion-header .accordion-button.btn-danger.customDanger {
  background: grey !important;
  border-color: grey !important;
}
ngb-accordion.profileNavigation .card-body, ngb-accordion.profileNavigation .accordion-body {
  padding: 16px 24px 16px;
}
ngb-accordion.profileNavigation .card-body ul, ngb-accordion.profileNavigation .accordion-body ul {
  padding-left: 0;
}
ngb-accordion.profileNavigation .card-body ul li, ngb-accordion.profileNavigation .accordion-body ul li {
  list-style-type: none;
  margin-bottom: 0.8rem;
  line-height: 1.2rem;
  font-weight: 500;
}
ngb-accordion.profileNavigation .card-body ul li a:hover, ngb-accordion.profileNavigation .accordion-body ul li a:hover {
  background: #777;
}
ngb-accordion .profileNavigationSub {
  background: #777 !important;
  box-shadow: none;
}
ngb-accordion .profileNavigationSub:hover {
  background: #777 !important;
}
ngb-accordion .profileNavigationSub .card-header, ngb-accordion .profileNavigationSub .accordion-header {
  color: #fff;
  text-transform: uppercase;
}
ngb-accordion .profileNavigationSub .card-header:hover, ngb-accordion .profileNavigationSub .accordion-header:hover {
  color: #fff;
}
ngb-accordion .profileNavigationSub .card-header a.btn, ngb-accordion .profileNavigationSub .accordion-header a.btn {
  text-transform: capitalize !important;
}
ngb-accordion .profileNavigationSub ul {
  padding-left: 0;
}
ngb-accordion .profileNavigationSub ul li {
  list-style-type: none;
}
ngb-accordion .summaryLinks p a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p a:hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p a:not([href]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p a:not([href]):not([tabindex]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p u {
  text-decoration: none !important;
}
ngb-accordion .summaryLinks p u a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p u a:hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p u a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p u a:not([href]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p u a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks p u a:not([href]):not([tabindex]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks a:hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks a:not([href]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks a:not([href]):not([tabindex]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks .checkboxContainer a {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks .checkboxContainer a:hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks .checkboxContainer a:not([href]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks .checkboxContainer a:not([href]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks .checkboxContainer a:not([href]):not([tabindex]) {
  color: #0076A0 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
ngb-accordion .summaryLinks .checkboxContainer a:not([href]):not([tabindex]):hover {
  color: #0076A0 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

.button-holder {
  justify-content: flex-end;
  display: flex;
  width: 94%;
  margin: auto;
}

/* You can add global styles to this file, and also import other style files */
#agenda .agendaTabs {
  border: 1px solid #ddd;
  border-top: 1px solid transparent;
  width: 100%;
  height: auto;
  float: left;
  clear: left;
  margin-bottom: 30px;
}
#agenda .agendaTabs ul.nav-tabs {
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  clear: left;
  margin: 0;
  padding: 0;
}
#agenda .agendaTabs ul.nav-tabs > .active a {
  border-bottom-color: none;
  border: none;
  background-color: transparent;
  font-weight: 200;
  color: #fff;
}
#agenda .agendaTabs ul.nav-tabs > li a {
  border-top: 1px solid #ddd;
  border-radius: 0 !important;
}
#agenda .agendaTabs ul.nav-tabs li {
  clear: none !important;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item {
  background: #FFFFFF;
  color: #333 !important;
  position: relative;
  display: table-cell;
  text-align: center;
  line-height: 1.4em;
  padding: 0;
  border-right: 1px solid #dadada;
  cursor: pointer;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  width: 25%;
}
@media (max-width: 430px) {
  #agenda .agendaTabs ul.nav-tabs li.nav-item {
    width: 50%;
    flex-wrap: wrap;
    border-left: 1px solid #dadada;
  }
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link {
  padding: 20px 10px;
  text-decoration: none !important;
  color: #222 !important;
}
@media (max-width: 845px) {
  #agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link {
    border-bottom: 1px solid #ddd;
  }
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link span {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link span.day {
  display: block;
  text-transform: capitalize;
  font-size: 22px;
  margin-bottom: 7px;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link span.date {
  display: block;
  font-size: 15px;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link span.curve {
  position: absolute;
  display: block;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background: none;
  text-align: center;
  opacity: 0;
}
@media (max-width: 845px) {
  #agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link span.curve {
    display: none;
  }
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link span.curve:after {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  border: 7px solid transparent;
  border-top-color: #dadada;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.nav-link:hover span.curve {
  bottom: -16px;
  opacity: 1;
  transition: all 300ms ease 200ms;
  -webkit-transition: all 300ms ease 200ms;
  -ms-transition: all 300ms ease 200ms;
  -o-transition: all 300ms ease 200ms;
  -moz-transition: all 300ms ease 200ms;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.hover {
  border-color: transparent;
  background: #222;
  color: #FFFFFF;
  border: none !important;
  outline: none !important;
  border-radius: 0;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.hover span.curve {
  bottom: -16px;
  opacity: 1;
  transition: all 300ms ease 200ms;
  -webkit-transition: all 300ms ease 200ms;
  -ms-transition: all 300ms ease 200ms;
  -o-transition: all 300ms ease 200ms;
  -moz-transition: all 300ms ease 200ms;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.active {
  border-color: transparent;
  background: #222;
  color: #FFFFFF !important;
  border: none !important;
  outline: none !important;
  border-radius: 0;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.active a {
  border-bottom: none !important;
  color: #FFFFFF !important;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.active span.curve {
  bottom: -16px;
  opacity: 1 !important;
  transition: all 300ms ease 200ms;
  -webkit-transition: all 300ms ease 200ms;
  -ms-transition: all 300ms ease 200ms;
  -o-transition: all 300ms ease 200ms;
  -moz-transition: all 300ms ease 200ms;
}
#agenda .agendaTabs ul.nav-tabs li.nav-item a.nav-link.active span.curve:after {
  border-top-color: #222 !important;
}
#agenda .agendaTabs .tab-content {
  width: 100%;
  height: auto;
  float: left;
  clear: left;
  padding: 40px 20px;
  background: #efefef;
  margin: 0 !important;
}
#agenda .agendaTabs .tab-content .tab-pane {
  width: 100%;
  height: auto;
  float: left;
  clear: left;
  position: relative;
  padding: 40px 20px;
}
#agenda .agendaTabs .tab-content .tab-pane h2 {
  color: #222;
}
@media (max-width: 845px) {
  #agenda .agendaTabs .tab-content .tab-pane h2 {
    font-size: 18px;
  }
}
#agenda .agendaTabs .tab-content .tab-pane .hour-box {
  position: relative;
  padding-left: 200px;
  font-size: 16px;
  color: #7f7f7f;
  padding-bottom: 20px;
  overflow: hidden;
}
@media (max-width: 845px) {
  #agenda .agendaTabs .tab-content .tab-pane .hour-box {
    padding-left: 40px;
  }
}
#agenda .agendaTabs .tab-content .tab-pane .hour-box:before {
  content: "";
  position: absolute;
  left: 181px;
  top: 24px;
  width: 2px;
  height: 100%;
  background: #d2d2d2;
}
@media (max-width: 845px) {
  #agenda .agendaTabs .tab-content .tab-pane .hour-box:before {
    left: 11px;
  }
}
#agenda .agendaTabs .tab-content .tab-pane .hour-box .hour {
  position: absolute;
  left: 0px;
  top: -4px;
  width: 230px;
  font-size: 14px;
  line-height: 2em;
  color: #333;
  text-transform: uppercase;
}
@media (max-width: 845px) {
  #agenda .agendaTabs .tab-content .tab-pane .hour-box .hour {
    position: relative;
    margin-bottom: 15px;
  }
}
#agenda .agendaTabs .tab-content .tab-pane .hour-box .circle {
  position: absolute;
  left: 170px;
  top: 0px;
  width: 24px;
  height: 24px;
  border: 2px solid #d2d2d2;
  border-radius: 100%;
}
@media (max-width: 845px) {
  #agenda .agendaTabs .tab-content .tab-pane .hour-box .circle {
    left: 0;
  }
}
#agenda .agendaTabs .tab-content .tab-pane .hour-box .toggle-btn h3 {
  margin-top: 0;
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  padding: 0;
}
@media (max-width: 845px) {
  #agenda .agendaTabs .tab-content .tab-pane .hour-box .toggle-btn h3 {
    font-size: 15px;
  }
}

#square-container {
  width: 100%;
}
#square-container .agenda-square {
  width: 33%;
  border: 1px solid #dfdfdf;
  background-color: #faf9f9;
  border-radius: 0.33rem;
  padding: 1rem;
  margin: 0 0.1rem;
}
#square-container .agenda-square ul {
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 1rem;
  margin-inline-start: 0px;
  margin-inline-end: 0;
  padding-inline-start: 0.5rem;
  border-bottom: 1px dotted #7f7f7f;
}
#square-container .agenda-square ul:last-child {
  border-bottom: none;
}
#square-container .agenda-square ul li:last-child {
  padding-bottom: 1rem;
}
#square-container .agenda-square ul li > ul {
  list-style-type: square;
}
#square-container .agenda-square ul li > ul > li {
  font-size: 0.86rem;
}
@media (max-width: 767px) {
  #square-container .agenda-square {
    width: 100%;
    margin: 0.1rem 0;
  }
}
#square-container .timeOfDay {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.98;
  background: antiquewhite;
  padding: 0.3rem;
  border-radius: 0.15rem;
}
#square-container .day {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
}

.header {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}
.header .logoutbar {
  width: 100%;
  background: transparent;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -34px;
  position: relative;
  z-index: 5;
}
.header .logoutbar a {
  margin-left: 1rem;
  color: #FFFFFF;
  font-size: 1rem;
}
.header .logoutbar a:hover {
  color: white;
}
.header .logoutbar a i {
  color: #FFFFFF;
}
.header .logoutbar a i:hover {
  color: white;
}
.header app-banner {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .header app-banner .mat-toolbar .example-spacer {
    flex: 1 1 auto;
  }
  .header app-banner .mat-toolbar .navitem {
    padding: 1rem;
  }
}
.header app-banner .mat-toolbar .navitem {
  cursor: pointer;
}
.header app-banner .bannerBackground {
  background: linear-gradient(#081c4e, #003867);
  width: 100%;
  height: 150px;
  border-bottom: 10px solid #00a9ec;
}
.header app-banner .bannerBackground .banner-img {
  width: 256px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-left: 2rem;
}
.header app-banner .bannerBackground .banner-img img {
  width: 100%;
}
.header .navbar .nav-link {
  padding: 0.5rem 1rem;
}
.header .navbar .navbar-toggler {
  border: 1px solid #fff;
  transition: background 0.5s linear;
}
.header .navbar .navbar-toggler:hover {
  background: rgba(255, 255, 255, 0.1);
  transition: background 0.5 linear;
}
.header .navbar .navbar-collapse .nav-item .is-register {
  color: #333;
}
.header .navbar .navbar-collapse .nav-item .dropdown .dropdown-menu {
  right: 0;
  left: auto;
  z-index: 1003 !important;
  color: #333;
}
@media (max-width: 991px) {
  .header .navbar .navbar-collapse .nav-item .dropdown .dropdown-menu {
    background: transparent;
    color: #fff;
    border-color: transparent;
    text-align: center;
  }
}
.header .navbar .navbar-collapse .nav-item .dropdown .dropdown-menu a {
  position: relative !important;
  z-index: 1005 !important;
  color: #333;
}
@media (max-width: 991px) {
  .header .navbar .navbar-collapse .nav-item .dropdown .dropdown-menu a:hover {
    opacity: 0.8;
  }
}
.header .navbar .navbar-collapse .nav-item.dropdownElement {
  position: relative;
  z-index: 1000;
}
@media (max-width: 991px) {
  .header .navbar .navbar-collapse .nav-item.dropdownElement {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.header .navbar .navbar-collapse .nav-item.dropdownElement .dropdown {
  z-index: 1001;
}
.header .navbar .navbar-collapse .nav-item.dropdownElement .dropdown a {
  z-index: 1002;
  background: transparent;
  color: #333;
}
@media (max-width: 991px) {
  .header .navbar .navbar-collapse .nav-item.dropdownElement .dropdown a {
    width: 100%;
    color: #FFFFFF;
  }
}
.header .navbar .navbar-collapse .nav-item.dropdownElement .dropdown a.dropdown-toggle {
  color: #FFFFFF;
}
.header .mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-step-header .mat-step-icon {
  width: 2rem;
  height: 2rem;
}
.header .mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-step-header .mat-step-icon.mat-step-icon-selected {
  background: #333;
  width: 4rem;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.4rem;
  border: 1px solid #222;
}
.header .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.header .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label {
  padding-top: 0px;
  margin-top: 0px;
  display: none;
}
.header .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label.mat-step-label-selected {
  border-radius: 5px;
  color: #333;
  padding: 5px 20px;
  display: block;
}

body.hideProgress .mat-stepper-horizontal {
  display: none !important;
}

#cart {
  text-align: center;
}
#cart mat-spinner {
  display: inline-block;
}
#cart table {
  width: 100%;
}

.cartTable tbody tr td {
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}
.cartTable tbody tr td:last-child {
  font-size: 1.4rem;
}
.cartTable tfoot tr td {
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}
.cartTable tfoot tr td:last-child {
  font-size: 1.4rem;
}

.creditCardDate {
  justify-content: space-between;
}
@media (max-width: 640px) {
  .creditCardDate {
    flex-direction: column;
  }
}
@media (max-width: 640px) {
  .creditCardDate .mat-form-field {
    width: 100%;
    margin-right: 0;
  }
}
.creditCardDate .mat-form-field:last-child {
  margin-right: 0;
}
.creditCardDate .mat-form-field.mat-form-field-type-mat-select app-validation {
  top: 35px;
}
.creditCardDate .left {
  width: calc(50% - 2px);
  padding-right: 2px;
}
.creditCardDate .right {
  width: calc(50% - 2px);
  padding-left: 2px;
}

.summaryCardDisplay {
  width: 100%;
  height: auto;
  max-width: 400px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.summaryCardDisplay .cardImage {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}
.summaryCardDisplay .cardImage img {
  width: 20%;
  max-width: 300px;
}
.summaryCardDisplay .cardInfo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-summary h3 {
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

.footer-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.footer-container img {
  max-width: 1124px;
}
.footer-container .footer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
  min-height: 100px;
  text-align: center;
}
.footer-container .footer .footer-items {
  margin-bottom: 0.8rem;
}
.footer-container .footer .material-icons {
  transform: scale(0.7);
}
.footer-container .footer a {
  color: #fff;
  margin-right: 1rem;
  cursor: pointer;
  text-decoration: none;
}
.footer-container .footer a:last-child {
  margin-right: 0;
}
.footer-container .footer a:hover {
  transition: all 0.2s ease-in-out;
  color: khaki;
}
.footer-container .footer a:hover .svg-fill {
  transition: all 0.2s ease-in-out;
  fill: khaki;
}
.footer-container .footer .mtlogo, .footer-container .footer svg {
  width: 100%;
  max-width: 100px;
  height: 2rem;
}
.footer-container .footer .maritz-url {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer-container .footer .maritz-url a {
  display: flex;
  align-items: center;
}
.footer-container .footer .svg-fill {
  fill: #ffffff;
  transition: all 0.2s ease-in-out;
}

.ngb-dp-day.disabled .outside {
  color: #6c757d !important;
  opacity: 0.5 !important;
}
.ngb-dp-day .outside {
  color: #212529 !important;
  opacity: 1 !important;
}

mat-dialog-container {
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 100% !important;
}
mat-dialog-container .close-container {
  position: relative;
  width: 50px;
  height: 50px;
  margin-top: 0px;
  cursor: pointer;
}
mat-dialog-container .leftright {
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #FFFFFF;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}
mat-dialog-container .rightleft {
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #FFFFFF;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}
mat-dialog-container .close {
  margin: 0px 0 0 5px;
  position: absolute;
}
mat-dialog-container .close-container:hover .leftright {
  transform: rotate(-45deg);
  background-color: #a6a6a6;
}
mat-dialog-container .close-container:hover .rightleft {
  transform: rotate(45deg);
  background-color: #a6a6a6;
}
mat-dialog-container .close-container:hover label {
  opacity: 1;
}
mat-dialog-container.mat-dialog-container {
  overflow: visible !important;
}
mat-dialog-container.mat-dialog-container .mat-dialog-content {
  background: #fff;
  padding: 50px 24px;
  height: 100%;
  top: 0;
  left: 0;
  max-height: 60vh;
  margin: 0 !important;
  padding: 0.75rem;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 768px) {
  mat-dialog-container.mat-dialog-container .mat-dialog-content {
    min-width: 1px;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 640px) {
  mat-dialog-container.mat-dialog-container .mat-dialog-content {
    padding: 0.5rem;
  }
}
@media only screen and (max-device-width: 640px) and (orientation: portrait) {
  mat-dialog-container.mat-dialog-container .mat-dialog-content {
    padding-bottom: 100px;
  }
}
mat-dialog-container.mat-dialog-container .mat-dialog-content.iframeContent {
  overflow: hidden !important;
}
mat-dialog-container.mat-dialog-container .mat-dialog-content .mat-expansion-panel-body {
  padding: 16px 24px 16px;
}
mat-dialog-container.mat-dialog-container .mat-dialog-content h2 {
  font-family: "Barlow Condensed", sans-serif !important;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #09244F;
  text-transform: uppercase;
}
mat-dialog-container.mat-dialog-container .mat-dialog-content mat-spinner {
  margin: 0 auto;
}
mat-dialog-container .innerModal {
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  min-height: 400px;
  max-height: 100%;
}
mat-dialog-container iframe {
  width: 100%;
  height: 100%;
  min-width: 800px;
  min-height: 400px;
  max-height: 100%;
  padding-bottom: 50px;
  overflow: scroll !important;
  overflow-x: initial !important;
}
@media (max-width: 768px) {
  mat-dialog-container iframe {
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-height: 500px) {
  mat-dialog-container iframe {
    padding-bottom: 150px;
  }
}

mat-dialog-container.mat-dialog-container {
  border: 3px solid #262626;
  border-radius: 11px;
  background-color: #1a1a1a;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 100% !important;
  padding: 0 10px !important;
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  mat-dialog-container.mat-dialog-container .modal-body .roomdetails {
    flex-direction: column;
  }
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelPhoto {
  width: 40%;
  margin-right: 1rem;
}
@media (max-width: 768px) {
  mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelPhoto {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelPhoto img {
  width: 100%;
  height: auto;
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy {
  width: 56%;
}
@media (max-width: 768px) {
  mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy {
    width: 100%;
  }
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy .hotelAddress {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  background: #efefef;
  border-radius: 0.5rem;
  padding: 1rem;
}
@media (max-width: 768px) {
  mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy .hotelAddress {
    text-align: center;
  }
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy .hotelAddress h2 {
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.4rem;
  font-weight: 300;
  margin-bottom: 0;
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy .hotelAddress p {
  margin-bottom: 0 !important;
  color: #333;
  font-weight: 400;
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy .hotelAddress p a {
  color: #0076A0;
  text-decoration: underline;
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy .hotelAddress p a:hover {
  color: #002b3a;
  text-decoration: none;
}
mat-dialog-container.mat-dialog-container .modal-body .roomdetails .hotelCopy .hotelDescription {
  font-weight: 400;
}
mat-dialog-container#modal-component {
  border: 3px solid #262626;
  border-radius: 11px;
  background-color: #1a1a1a;
}
mat-dialog-container#modal-component-logout {
  border: 3px solid #262626;
  border-radius: 11px;
  background-color: #1a1a1a;
  min-height: 200px !important;
  min-width: 700px !important;
  max-width: 700px !important;
}
@media (max-width: 768px) {
  mat-dialog-container#modal-component-logout {
    max-width: 100% !important;
    min-width: 1px !important;
  }
}
mat-dialog-container .button_group {
  padding-top: 0.5rem;
}
mat-dialog-container .button_group .btn-outline-primary {
  border-color: #FFFFFF;
  color: #FFFFFF;
}
mat-dialog-container .button_group .btn-outline-primary:hover {
  background: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
  color: #FFFFFF;
}
mat-dialog-container .button_group .cancelBtn {
  margin-right: auto;
  margin-left: 1rem;
}
@media (max-width: 640px) {
  mat-dialog-container .button_group .cancelBtn {
    margin-right: 0;
    margin-left: 0;
  }
}

.modal-content-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  color: #444;
}

.mat-card a, .mat-card a:hover, .modal-content-wrapper a, .modal-content-wrapper a:hover {
  color: #0069d9 !important;
}

.modal-header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: none;
  padding-left: 0;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.modal-title {
  font-size: 22px;
  color: #fff;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#modal-footer {
  padding: 0.2rem 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.modal-action-button {
  margin-right: 30px;
  background-color: #3b3b3b;
  color: white;
}

.modal-cancel-button {
  background-color: #4e4e4e;
  color: white;
}

.btn.closeButton {
  border-radius: 50% !important;
}

app-room-detail-dialog {
  position: relative;
}

#modal-action-button {
  margin-left: auto;
}

.IEmesssage {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
  background: #b0383b;
}
.IEmesssage i {
  margin-right: 1rem;
  color: #fff;
}
.IEmesssage p {
  color: #900;
  color: #fff;
}

i.fas.fa-asterisk.required {
  color: #B30000;
}

.md-form {
  width: 100%;
}
.md-form input {
  width: 100%;
}
.md-form label.required {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.md-form label.required:after {
  content: "*";
  color: #B30000;
  padding-left: 5px;
  position: relative;
  right: 0;
  top: 0;
  opacity: 1;
}

form.form-complete .mat-radio-group.ng-touched.ng-invalid .mat-radio-outer-circle {
  border-color: #B30000;
}
form.form-complete .mat-radio-group.ng-invalid .mat-radio-outer-circle {
  border-color: #B30000;
}
form.form-complete .mat-radio-group.ng-invalid + label {
  color: #B30000;
}

div.md-form.required label:after {
  content: "*";
  color: #B30000;
  padding-left: 5px;
  position: relative;
  right: 0;
  top: 0;
  opacity: 1;
  display: inline;
}
div.md-form.required label.mat-radio-label:after {
  content: "";
}
div.required label:after {
  content: "*";
  color: #B30000;
  padding-left: 5px;
  position: relative;
  right: 0;
  top: 0;
  opacity: 1;
}
div.required label.mat-radio-label:after {
  content: "";
}
div.required label.mat-checkbox-layout:after {
  content: "";
}

.agreeCheckbox mat-checkbox + label {
  margin-bottom: 0;
  position: relative;
  top: -3px;
}
.agreeCheckbox mat-checkbox + label.required:after {
  content: "*";
  color: #B30000;
  padding-left: 5px;
  position: relative;
  right: 0;
  top: 0;
  opacity: 1;
  font-size: 1rem;
}
.agreeCheckbox mat-checkbox + label a:hover {
  text-decoration: none;
}

textarea.form-control:focus {
  border-color: none;
  box-shadow: none;
}

.mat-form-field-label span.mat-form-field-required-marker {
  color: #B30000 !important;
}

div.required label:after {
  color: #B30000 !important;
  font-weight: bold !important;
}

.mat-error, .text-danger {
  color: #B30000 !important;
  font-weight: bold !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #B30000 !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #B30000 !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #B30000 !important;
}