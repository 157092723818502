mat-dialog-container {
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 100% !important;

  .close-container {
    position: relative;
    width: 50px;
    height: 50px;
    margin-top: 0px;
    cursor: pointer;
  }

  .leftright {
    height: 4px;
    width: 50px;
    position: absolute;
    margin-top: 24px;
    background-color: $brand-white;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all .3s ease-in;
  }

  .rightleft {
    height: 4px;
    width: 50px;
    position: absolute;
    margin-top: 24px;
    background-color: $brand-white;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all .3s ease-in;
  }


  .close {
    margin: 0px 0 0 5px;
    position: absolute;
  }

  .close-container:hover .leftright {
    transform: rotate(-45deg);
    background-color: $brand-primary-lighter;
  }

  .close-container:hover .rightleft {
    transform: rotate(45deg);
    background-color: $brand-primary-lighter;
  }

  .close-container:hover label {
    opacity: 1;
  }

  &.mat-dialog-container {
    overflow: visible !important;
    // -webkit-overflow-scrolling: touch;
    //max-height: 500px;

    .mat-dialog-content {
      background: #fff;
      padding: 50px 24px;
      height: 100%;
      top: 0;
      left: 0;
      max-height: 60vh;
      margin: 0 !important;
      padding: .75rem;
      overflow: auto !important;
      -webkit-overflow-scrolling: touch;

      @media (max-width: 768px) {
        min-width: 1px;
        max-width: 100%;
        width: 100%;
        margin: 0;
      }

      @media (max-width: 640px) {
        padding: .5rem;
      }

      @media only screen and (max-device-width: 640px) and (orientation: portrait) {
        padding-bottom: 100px;
      }

      &.requestedRoomsModal {}

      &.iframeContent {
        overflow: hidden !important;
        //max-height: 100%;
      }

      .mat-expansion-panel-body {
        padding: 16px 24px 16px;
      }

      h2 {
        font-family: "Barlow Condensed", sans-serif !important;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: #09244F;
        text-transform: uppercase;
      }

      mat-spinner {
        margin: 0 auto;
      }
    }
  }

  .innerModal {
    height: 100%;
    width: 100%;
    padding-bottom: 50px;
    min-height: 400px;
    max-height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    //min-height: 1800px;
    min-width: 800px;
    min-height: 400px;
    max-height: 100%;
    padding-bottom: 50px;
    overflow: scroll !important;
    overflow-x: initial !important;

    @media (max-width: 768px) {
      min-width: 100%;
      max-width: 100%;
    }

    @media (max-height: 500px) {
      padding-bottom: 150px;
    }
  }
}

mat-dialog-container {
  &.mat-dialog-container {
    border: 3px solid #262626;
    border-radius: 11px;
    background-color: #1a1a1a;
    height: 100%;
    top: 0;
    left: 0;
    max-width: 100% !important;
    padding: 0 10px !important;

    .modal-body {
      .roomdetails {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        .hotelPhoto {
          width: 40%;
          margin-right: 1rem;

          @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 2rem;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .hotelCopy {
          width: 56%;

          @media (max-width: 768px) {
            width: 100%;
          }

          .hotelAddress {
            width: 100%;
            height: auto;
            margin-bottom: 1rem;
            background: #efefef;
            border-radius: .5rem;
            padding: 1rem;

            @media (max-width: 768px) {
              text-align: center;
            }

            h2 {
              font-size: 1.6rem;
              color: #333;
              letter-spacing: .4rem;
              font-weight: 300;
              margin-bottom: 0;
            }

            p {
              margin-bottom: 0 !important;
              color: #333;
              font-weight: 400;

              a {
                color: $ConsistentLinkColor;
                text-decoration: underline;

                &:hover {
                  color: $ConsistentLinkColorHover;
                  text-decoration: none;
                }
              }
            }
          }

          .hotelDescription {
            font-weight: 400;

          }
        }

      }
    }
  }

  &#modal-component {
    border: 3px solid #262626;
    border-radius: 11px;
    background-color: #1a1a1a;
  }

  &#modal-component-logout {
    border: 3px solid #262626;
    border-radius: 11px;
    background-color: #1a1a1a;
    min-height: 200px !important;
    min-width: 700px !important;
    max-width: 700px !important;

    @media (max-width: 768px) {
      max-width: 100% !important;
      min-width: 1px !important;
    }
  }

  // &#mat-dialog-1 {
  //   min-width: 600px !important;
  //   max-width: 600px !important;
  // }

  .button_group {
    padding-top: .5rem;

    .btn-outline-primary {
      border-color: $brand-white;
      color: $brand-white;

      &:hover {
        background: $brand-primary-lighter !important;
        border-color: $brand-primary-lighter !important;
        color: $brand-white;
      }
    }

    .cancelBtn {
      margin-right: auto;
      margin-left: 1rem;

      @media (max-width: 640px) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

}

.modal-content-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  color: #444;
}

.mat-card, .modal-content-wrapper {
 a, a:hover {
  color: #0069d9 !important;
  }
}

.modal-header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: none;
  padding-left: 0;
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
}

.modal-title {
  font-size: 22px;
  color: #fff;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

}

#modal-footer {
  padding: .2rem 0 .5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.modal-action-button {
  margin-right: 30px;
  background-color: #3b3b3b;
  color: white;
}

.modal-cancel-button {
  background-color: #4e4e4e;
  color: white;
}

.btn {
  &.closeButton {
    border-radius: 50% !important;
  }
}

app-room-detail-dialog {
  position: relative;
}

#modal-action-button {
  margin-left: auto
}
