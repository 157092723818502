#guest {
  min-height: 100vh;

  .add-guest {
    margin-bottom: 1rem;
    //Comment out for default success color
    background: $brand-secondary !important;
    border: $brand-secondary !important;

    &:hover {
      background: $brand-secondary-darker !important;
      border: $brand-secondary-darker !important;
    }
  }
}

.nameFields {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .firstNameInput {
    width: 80%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .lastNameInput {
    width: 80%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .prefixInput {
    width: 15%;
    top: 6px;

    @media (max-width: 1199px) {
      top: 5px;
    }

    @media (max-width: 640px) {
      width: 100%;
      top: 0px;
    }
  }

  .suffixInput {
    width: 15%;
    top: 6px;

    @media (max-width: 1199px) {
      top: 5px;
    }

    @media (max-width: 640px) {
      width: 100%;
      top: 0px;
    }
  }
}


.mat-expansion-panel .mat-expansion-panel-header {
  background-color: #ccc;
  border-radius: 0;
}

mat-accordion {
  display: block;
}
