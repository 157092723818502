.header {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  .logoutbar {
    width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -34px;
    position: relative;
    z-index: 5;

    a {
      margin-left: 1rem;
      color: $brand-white;
      font-size: 1rem;
      
      &:hover {
        color: $brand-white-lighter;
      }

      i {
        color: $brand-white;

        &:hover {
          color: $brand-white-lighter;
        }
      }
    }
  }

  app-banner {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;

    .mat-toolbar {
      @media (max-width: 768px) {
        //display: inline;
      }

      @media (min-width: 768px) {
        .example-spacer {
          flex: 1 1 auto;
        }

        .navitem {
          padding: 1rem;
        }
      }

      .navitem {
        cursor: pointer;
      }
    }

    .bannerBackground {
      background: linear-gradient(#081c4e, #003867);
      width: 100%;
      height: 150px;
      border-bottom: 10px solid #00a9ec;

      .banner-img {
        width: 256px;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        padding-top: 2rem;
        padding-left: 2rem;

        img {
          width: 100%;
        }
      }
    }
  }

  .navbar {

    .nav-link{
      padding: 0.5rem 1rem;
    }

    .navbar-toggler {
      border: 1px solid #fff;
      transition: background .5s linear;

      &:hover {
        background: rgba(255, 255, 255, .1);
        transition: background .5 linear;
      }
    }

    .navbar-collapse {
      .nav-item {
        &:nth-child(2) {
          //margin-right: auto;

          @media (max-width: 991px) {
            //margin-right: 0;
          }
        }

        .is-register{
          color: $brand-primary;
        }

        .dropdown {

          // a {}

          .dropdown-menu {
            right: 0;
            left: auto;
            z-index: 1003 !important;
            color: $brand-primary;

            @media (max-width: 991px) {
              background: transparent;
              color: #fff;
              border-color: transparent;
              text-align: center;
            }

            a {
              position: relative !important;
              z-index: 1005 !important;
              color: $brand-primary;


              &:hover {
                @media (max-width: 991px) {
                  opacity: .8;
                }
              }
            }
          }
        }

        &.dropdownElement {
          position: relative;
          z-index: 1000;

          @media (max-width: 991px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }

          .dropdown {
            z-index: 1001;

            @media (max-width: 991px) {}

            a {
              z-index: 1002;
              background: transparent;
              color: $brand-primary;

              @media (max-width: 991px) {
                width: 100%;
                color: $brand-white;
              }

              &.dropdown-toggle {
                color: $brand-white;
              }
            }
          }
        }
      }
    }
  }

  .mat-stepper-horizontal {
    &.mat-stepper-label-position-bottom {
      .mat-step-header {
        .mat-step-icon {
          width: 2rem;
          height: 2rem;

          &.mat-step-icon-selected {
            //Custom Current State Progress cutom color otherwise will be brand primary color
            background: $brand-primary;
            width: 4rem;
            border-radius: 20px;
            font-weight: bold;
            font-size: 1.4rem;
            border: 1px solid $brand-secondary;
          }
        }
      }
    }
  }



  .mat-stepper-label-position-bottom {
    .mat-horizontal-stepper-header-container {

      flex-wrap: wrap;
      justify-content: space-evenly;

      .mat-horizontal-stepper-header {
        .mat-step-label {
          padding-top: 0px;
          margin-top: 0px;
          display: none;

          &.mat-step-label-selected {
            //background: $brand-primary;
            border-radius: 5px;
            color: $brand-primary;
            padding: 5px 20px;
            display: block;
          }
        }
      }
    }
  }
}

body {
  &.hideProgress {
    .mat-stepper-horizontal {
      display: none!important;
    }
  }
}