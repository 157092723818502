.IEmesssage {
  width: 100%;
  height: auto;
  //box-shadow: 0 4px 2px -2px gray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  //margin-bottom: 1rem;
  padding: 1rem;
  background: #b0383b;

  i {
    margin-right: 1rem;
    color: #fff;
  }

  p {
    color: #900;
    color: #fff;
  }
}
