@import '../../../app/app.variables.scss';

app-guest-travel {
	width: 100%;

	.travelLinksPrimaryAndGuest {
    p {
      a {
        color: $ConsistentLinkColor !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: $ConsistentLinkColorHover !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([href]) {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([tabindex]) {
            color: $ConsistentLinkColor !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: $ConsistentLinkColorHover !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }
          }
        }
      }

      u {
        text-decoration: none !important;

        a {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([href]) {
            color: $ConsistentLinkColor !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: $ConsistentLinkColorHover !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }

            &:not([tabindex]) {
              color: $ConsistentLinkColor !important;
              text-decoration: underline !important;
              font-weight: bold !important;

              &:hover {
                color: $ConsistentLinkColorHover !important;
                text-decoration: none !important;
                font-weight: bold !important;
              }
            }
          }
        }
      }
    }
  }
}