.creditCardDate {
	// height: auto;
	// display: flex;
	 justify-content: space-between;
	// flex-wrap: nowrap;
	// flex-direction: row;

	@media (max-width: 640px) {
			flex-direction: column;
		}

	.mat-form-field {
		

		@media (max-width: 640px) {
			width: 100%;
			margin-right: 0;
		}

		&:last-child {
			margin-right: 0;
		}
		
		&.mat-form-field-type-mat-select {
			app-validation {
				top: 35px;
			}
		}
	}

	.left {
		width: calc(50% - 2px);
		padding-right: 2px;
	}
	.right {
		width: calc(50% - 2px);
		padding-left: 2px;
	}
}
