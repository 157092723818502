#acknowledgment {
  width: 100%;

  .travelLinksPrimaryAndGuest {
    p {
      a {
        color: #0076A0 !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: #0076A0 !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([href]) {
          color: #0076A0 !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: #0076A0 !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([tabindex]) {
            color: #0076A0 !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: #0076A0 !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }
          }
        }
      }

      u {
        text-decoration: none !important;

        a {
          color: #0076A0 !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: #0076A0 !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([href]) {
            color: #0076A0 !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: #0076A0 !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }

            &:not([tabindex]) {
              color: #0076A0 !important;
              text-decoration: underline !important;
              font-weight: bold !important;

              &:hover {
                color: #0076A0 !important;
                text-decoration: none !important;
                font-weight: bold !important;
              }
            }
          }
        }
      }
    }

    a {
      color: #0076A0 !important;
      text-decoration: underline !important;
      font-weight: bold !important;

      &:hover {
        color: #0076A0 !important;
        text-decoration: none !important;
        font-weight: bold !important;
      }

      &:not([href]) {
        color: #0076A0 !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: #0076A0 !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([tabindex]) {
          color: #0076A0 !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: #0076A0 !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }
        }
      }
    }
  }
}
