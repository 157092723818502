@import '../../../app/app.variables.scss';

#profile {
  min-height: 60vh;
  height: auto;


  .card {
    &.profileCard {
      max-width: 700px;
      margin: 0 auto 2rem !important;

      .profileInstructions {
        margin-bottom: 1rem;
      }

      section,
      article,
      form,
      app-profile-login {

        width: 100%;
        height: auto;

        .profileBody {
          padding: 2rem;
        }
      }
    }
  }

  .profileAll {
    .button_group {
      padding-left: 20px;

      &.profileNew {
        padding-left: 0px !important;
        margin-bottom: 0;
      }
    }
  }
}

.profileEdit {
  #profile {
    .card {
      &.profileCard {
        max-width: 100% !important;
      }
    }
  }
}

.modifyProfile {
  .card, .accordion-item {
    background: #F0F0F0;

    .card-title, .accordion-header {
      //padding: .5rem;
      background: #D8D8D8;

    }

    h2 {
      margin-top: 1rem;
      background: #D8D8D8;
      padding: .5rem 1rem;
      border-radius: 10px;
    }

    h3 {
      color: #555;
      margin: 1rem 0;
    }
  }

  .addGuest {
    margin-bottom: 2rem !important;
  }

  .addRemoveButton {
    display: flex;
    justify-content: flex-end;
  }
}

.phoneflex {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addRemoveButton {
  .btn {
    margin-bottom: 1rem;
  }
}



.topNote {
  width: 100%;
  height: auto;
  padding: 2rem;
  font-size: 1.6rem;
}



ngb-accordion {
  &.profileNavigation {
    margin: 1rem 0;

    .card, .accordion-item {
      margin-bottom: 0 !important;

      &:first-child {

        .card-header, .accordion-header {
          padding-left: 0;
          background: $brand-primary!important;

          &:hover {
            background: $brand-primary!important;
          }

          .btn, .accordion-button {
            color: #fff;
            font-weight: 500;
            font-size: 1.4rem;
            text-decoration: none;
          }
        }

        .card-body, .accordion-body {
          padding: 16px 24px 16px;

          ul {
            padding-left: 0;

            li {
              list-style-type: none;
              margin-bottom: .8rem;
              line-height: 1.2rem;
              font-weight: 500;


              a {
                &:hover {
                  color: rgba(28, 38, 104, 1);
                }
              }
            }
          }
        }
      }

      .card-header, .accordion-header {
        background: #999 !important;
        padding: 0;
        padding-left: 1rem;

        &:hover {
          background: #999 !important;
        }

        &#admin-header {
          display: flex;
          flex-direction: column;
          padding-left: 0;
          background: $brand-primary !important;

          &:hover {
            background: $brand-primary !important;
          }

          .btn, .accordion-button  {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            a {}
          }

          +div {
            &#admin {
              display: none !important;
            }
          }
        }

        &#addguest-header {
          padding: 0 !important;

          +div {
            &#addguest {
              display: none !important;
            }
          }
        }

        &#saveprofile-header {
          padding: 0 !important;

          +div {
            &#saveprofile {
              display: none !important;
            }
          }
        }

        .btn, .accordion-button {
          color: #fff;
          font-weight: 500;
          font-size: 1.1rem;
          text-decoration: none;
          width: 100%;
          text-align: left;
          margin-bottom: 0 !important;
          display: flex;
          justify-content: space-between;
          background: transparent;
          border: none!important;

          i {}

          &[aria-expanded="true"] {
            i {
              transform: rotate(-180deg);
            }
          }

          &.btn-success {
            &.customSuccess {
              background: #FAD141 !important;
              border-color: #FAD141 !important;
            }
          }

          &.btn-danger {
            &.customDanger {
              background: grey !important;
              border-color: grey !important;
            }
          }

        }
      }
    }

    .card-body, .accordion-body {
      padding: 16px 24px 16px;

      ul {
        padding-left: 0;

        li {
          list-style-type: none;
          margin-bottom: .8rem;
          line-height: 1.2rem;
          font-weight: 500;


          a {
            &:hover {
              background: #777;
            }

          }
        }
      }
    }
  }

  .profileNavigationSub {
    background: #777 !important;
    box-shadow: none;
    //border-top: rgba(28, 38, 104, 1) solid 1px;
    //border-bottom: rgba(28, 38, 104, 1) solid 1px;

    &:hover {
      background: #777 !important;
    }

    .card-header, .accordion-header {
      color: #fff;
      text-transform: uppercase;

      &:hover {
        color: #fff;
      }

      a {
        &.btn {

          text-transform: capitalize !important;
        }
      }
    }

    ul {
      padding-left: 0;

      li {
        list-style-type: none;

      }
    }
  }



  .summaryLinks {
    p {
      a {
        color: #0076A0 !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: #0076A0 !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([href]) {
          color: #0076A0 !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: #0076A0 !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([tabindex]) {
            color: #0076A0 !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: #0076A0 !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }
          }
        }
      }

      u {
        text-decoration: none !important;

        a {
          color: #0076A0 !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: #0076A0 !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([href]) {
            color: #0076A0 !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: #0076A0 !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }

            &:not([tabindex]) {
              color: #0076A0 !important;
              text-decoration: underline !important;
              font-weight: bold !important;

              &:hover {
                color: #0076A0 !important;
                text-decoration: none !important;
                font-weight: bold !important;
              }
            }
          }
        }
      }
    }

    a {
      color: #0076A0 !important;
      text-decoration: underline !important;
      font-weight: bold !important;

      &:hover {
        color: #0076A0 !important;
        text-decoration: none !important;
        font-weight: bold !important;
      }

      &:not([href]) {
        color: #0076A0 !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: #0076A0 !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([tabindex]) {
          color: #0076A0 !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: #0076A0 !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }
        }
      }
    }

    .checkboxContainer {
      a {
        color: #0076A0 !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: #0076A0 !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([href]) {
          color: #0076A0 !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: #0076A0 !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([tabindex]) {
            color: #0076A0 !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: #0076A0 !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }
          }
        }
      }
    }
  }

}

.button-holder {
  justify-content: flex-end;
  display: flex;
  width: 94%;
  margin: auto;
}
