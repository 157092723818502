#cart {
  text-align: center;

  mat-spinner {
    display: inline-block;
  }

  table {
    width: 100%;
  }
}

.cartTable {
  thead {}

  tbody {
    tr {
      td {
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;

        &:last-child {
          font-size: 1.4rem;
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;

        &:last-child {
          font-size: 1.4rem;
        }
      }
    }
  }
}