#attendee {
  
  .firstNameInput {
    width: 80%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .lastNameInput {
    width: 80%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .prefixInput {
    width: 15%;
    top: 6px;

    @media (max-width: 1199px) {
      top: 5px;
    }

    @media (max-width: 640px) {
      width: 100%;
      top: 0px;
    }
  }

  .suffixInput {
    width: 15%;
    top: 6px;

    @media (max-width: 1199px) {
      top: 5px;
    }

    @media (max-width: 640px) {
      width: 100%;
      top: 0px;
    }
  }

  app-phone {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    fieldset {
      width: 100%;
      height: auto;

      .md-form {
        width: 100%;
        height: auto;
        max-width: 600px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        span {
          &.country {
            width: 30%;
            margin-right: 1em;

            input {
              width: 100%;
            }
          }

          &.area {
            width: 30%;
            margin-right: 1em;

            input {
              width: 100%;
            }
          }

          &.intphone {
            width: 30%;
            margin-right: 0;

            input {
              width: 100%;
            }
          }
        }

        div {
          &.ng-star-inserted {
            width: 100%;
            align-self: flex-end;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .ng-star-inserted {
    width: 100%;
  }

  .btn {
    &.ng-star-inserted {
      width: auto;
    }
  }
}
