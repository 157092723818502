@import '../../../app/app.variables.scss';

#travel {

  h3 {
  font-size: 1.125rem !important;
  margin: 1rem auto;
  }
  ngb-datepicker {
    //padding: 2rem;
    width: 80%;
    margin-bottom: 1rem;

    @media (max-width: 640px) {
      width: 100%;
    }

    .ngb-dp-month {
      width: 100%;

      ngb-datepicker-month {

        .ngb-dp-week {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
  }

  .travelLinksPrimaryAndGuest {
    p {
      a {
        color: $ConsistentLinkColor !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: $ConsistentLinkColorHover!important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([href]) {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([tabindex]) {
            color: $ConsistentLinkColor !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: $ConsistentLinkColorHover !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }
          }
        }
      }

      u {
        text-decoration: none !important;

        a {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([href]) {
            color: $ConsistentLinkColor !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: $ConsistentLinkColorHover !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }

            &:not([tabindex]) {
              color: $ConsistentLinkColor !important;
              text-decoration: underline !important;
              font-weight: bold !important;

              &:hover {
                color: $ConsistentLinkColorHover !important;
                text-decoration: none !important;
                font-weight: bold !important;
              }
            }
          }
        }
      }
    }
  }
}

#travel a {
    text-decoration: underline;
    color: #0076A0;
}