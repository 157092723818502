#cancellation {
  i {
    &.fas {
      &.fa-asterisk {
        &.required {
        	font-size: 6px;
        	top: -10px;
        	position: relative;
        }
      }
    }
  }
}