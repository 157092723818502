.summaryCardDisplay {
  width: 100%;
  height: auto;
  max-width: 400px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;

  .cardImage {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;

    img {
      width: 20%;
      max-width: 300px;
    }
  }

  .cardInfo {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.form-summary {
  h3 {
    font-weight: bold !important;
    font-size: 1.2rem !important;
  }
}
