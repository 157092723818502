i {
  &.fas {
    &.fa-asterisk {
      &.required {
        color: #B30000;
      }
    }
  }
}

.md-form {
  width: 100%;

  input {
    width: 100%;

    // &.form-control {
    //   &.ng-invalid {
    //     &.ng-touched {
    //       border-bottom: 1px solid red;

    //       +label {
    //         &.required {
    //           color: #B30000;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // fieldset {
  //   &.ng-invalid {
  //     +label {
  //       &.required {
  //         color: #B30000;
  //       }
  //     }
  //   }
  // }

  label {
    &.required {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      &:after {
        content: '*';
        color: #B30000;
        padding-left: 5px;
        position: relative;
        right: 0;
        top: 0;
        opacity: 1;
      }
    }
  }
}

// .mat-form-field-required-marker {
//   color: #B30000;
//   font-size: 1rem;
// }

// mat-form-field {
//   padding-bottom: .5rem;
//   padding-top: .5rem;

//   input {
//     &.form-control {
//       &:focus {
//         /*border: none;*/
//         /*outline: none;*/
//         box-shadow: none;
//       }

//       &.ng-invalid {
//         mat-form-field-invalid {
//           .mat-form-field-ripple {
//             color: rgba(0, 0, 0, .87);
//           }
//         }
//       }

//       &.ng-touched {
//         &.ng-invalid {
//           mat-form-field-invalid {
//             .mat-form-field-ripple {
//               background: red;
//             }
//           }

//         }
//       }
//     }
//   }

//   &.mat-form-field-type-mat-input {
//     .mat-form-field-infix {
//       padding: 0;
//     }

//     &.birthdate {
//       .mat-form-field-infix {
//         padding: .4375em 0;
//       }
//     }
//   }
// }

form {
  &.form-complete {

//     mat-form-field {
//       input {
//         &.form-control {
//           &.ng-invlaid {}
//         }
//       }

//       mat-form-field-invalid {
//         .mat-form-field-ripple {
//           background: red;
//         }
//       }
//     }

    .mat-radio-group {
      &.ng-touched {
        &.ng-invalid {
          .mat-radio-outer-circle {
            border-color: #B30000;
          }
        }
      }

      &.ng-invalid {
        .mat-radio-outer-circle {
          border-color: #B30000;
        }

        +label {
          color: #B30000;
        }
      }
    }
  }
}

div {
  &.md-form {
    &.required {
      label {
        &:after {
          content: '*';
          color: #B30000;
          padding-left: 5px;
          position: relative;
          right: 0;
          top: 0;
          opacity: 1;
          display: inline;
        }

        &.mat-radio-label {
          &:after {
            content: '';
          }
        }
      }
    }
  }

  &.required {
    label {
      &:after {
        content: '*';
        color: #B30000;
        padding-left: 5px;
        position: relative;
        right: 0;
        top: 0;
        opacity: 1;
      }

      &.mat-radio-label {
        &:after {
          content: '';
        }
      }

      &.mat-checkbox-layout {
        &:after {
          content: '';
        }
      }
    }
  }
}

.agreeCheckbox {
  mat-checkbox {

    +label {
      margin-bottom: 0;
      position: relative;
      top: -3px;

      &.required {
        &:after {
          content: '*';
          color: #B30000;
          padding-left: 5px;
          position: relative;
          right: 0;
          top: 0;
          opacity: 1;
          font-size: 1rem;
        }
      }

      a {
        // color: #007bff !important;
        // text-decoration: none !important;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// .intlphone {
//   app-validation {
//     position: static;
//   }
// }

// app-validation {
//   position: relative;
//   top: 25px;

//   .thq-error-message {
//     display: none;

//     &.touched {
//       display: block;
//       margin-bottom: 0px;
//     }
//   }
// }

// .mat-form-field-type-mat-select {
//   app-validation {
//     position: relative;
//     top: 25px;

//     .thq-error-message {
//       display: none;

//       &.touched {
//         display: block;
//         margin-bottom: 0px;
//       }
//     }
//   }
// }

// app-date {
//   fieldset {
//     &.ng-untouched {
//       app-validation {
//         position: relative;
//         top: 32px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }

//     &.ng-touched {
//       app-validation {
//         position: relative;
//         top: 42px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }
//   }
// }

// app-phone {
//   fieldset {
//     &.ng-untouched {
//       app-validation {
//         position: relative;
//         top: 32px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }

//     &.ng-touched {
//       app-validation {
//         position: relative;
//         top: 42px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }
//   }
// }

// .mat-form-field {
//   +app-validation {
//     position: relative;
//     top: 0px;
//   }
// }

// .mat-form-field {
//   &.ng-touched {
//     app-validation {
//       display: block;
//     }
//   }
// }

// .mat-radio-group {
//   div {
//     app-validation {
//       position: relative;
//       top: 0px;
//     }
//   }
// }

// .agreeCheckbox {
//   app-validation {
//     position: relative;
//     top: 0px;
//   }
// }

// form {
//   &.form-complete {
//     &.ng-invalid {
//       app-validation {
//         display: block;

//         .thq-error-message {
//           display: block;
//         }
//       }
//     }

//     .mat-checkbox {

//       +label {
//         margin-bottom: 0;
//         position: relative;
//         top: -3px;

//         &.required {
//           &:after {
//             content: '*';
//             color: #B30000;
//             padding-left: 5px;
//             position: relative;
//             right: 0;
//             top: 0;
//             opacity: 1;
//             font-size: 1rem;
//           }

//           a {
//             color: #007bff !important;
//             text-decoration: none !important;

//             &:hover {
//               text-decoration: none;
//             }
//           }
//         }
//       }

//       .mat-checkbox-inner-container {
//         .mat-checkbox-frame {
//           border-color: #444;
//         }
//       }

//       &.ng-invalid {
//         .mat-checkbox-inner-container {
//           .mat-checkbox-frame {
//             border-color: #B30000;
//             color: #B30000;
//           }
//         }
//       }
//     }
//   }
// }

// form {
//   &.form-complete {
//     app-validation {
//       .nonstandard {
//         display: block;

//         .thq-error-message {
//           display: block;
//         }
//       }
//     }
//   }
// }

// app-date {
//   fieldset {
//     &.ng-untouched {
//       app-validation {
//         position: relative;
//         top: 32px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }

//     &.ng-touched {
//       app-validation {
//         position: relative;
//         top: 42px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }
//   }
// }

// app-phone {
//   fieldset {
//     &.ng-untouched {
//       app-validation {
//         position: relative;
//         top: 32px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }

//     &.ng-touched {
//       app-validation {
//         position: relative;
//         top: 42px;

//         .thq-error-message {
//           display: none;

//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }
//   }
// }

// .mat-form-field {
//   +app-validation {
//     position: relative;
//     top: 0px;
//   }
// }

// .mat-form-field {
//   &.ng-touched {
//     app-validation {
//       display: block;
//     }
//   }
// }

// .mat-radio-group {
//   div {
//     app-validation {
//       position: relative;
//       top: 0px;
//     }
//   }
// }

// .agreeCheckbox {
//   app-validation {
//     position: relative;
//     top: 0px;
//   }
// }

// form {
//   &.form-complete {
//     &.ng-invalid {
//       app-validation {
//         display: block;

//         .thq-error-message {
//           display: block;
//         }
//       }
//     }

//     .mat-checkbox {

//       +label {
//         margin-bottom: 0;
//         position: relative;
//         top: -3px;

//         &.required {
//           &:after {
//             content: '*';
//             color: #B30000;
//             padding-left: 5px;
//             position: relative;
//             right: 0;
//             top: 0;
//             opacity: 1;
//             font-size: 1rem;
//           }

//           a {
//             color: #007bff !important;
//             text-decoration: none !important;

//             &:hover {
//               text-decoration: none;
//             }
//           }
//         }
//       }

//       .mat-checkbox-inner-container {
//         .mat-checkbox-frame {
//           border-color: #444;
//         }
//       }

//       &.ng-invalid {
//         .mat-checkbox-inner-container {
//           .mat-checkbox-frame {
//             border-color: #B30000;
//             color: #B30000;
//           }
//         }
//       }
//     }
//   }
// }

// form {
//   &.form-complete {
//     textarea{
//       &.ng-invalid{
//           border-color: #ff3547;
//       }
//     }
//     app-validation {
//       .nonstandard {
//         display: block;

//         .thq-error-message {
//           display: block;
//         }
//       }
//     }
//   }
// }


// app-phone {
//   fieldset {
//     &.ng-untouched {
//       app-validation {
//         position: relative;
//         top: 32px;



//         .thq-error-message {
//           display: none;



//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }

//     &.ng-touched {
//       app-validation {
//         position: relative;
//         top: 42px;



//         .thq-error-message {
//           display: none;



//           &.touched {
//             display: block;
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }
//   }
// }

textarea {
  &.form-control {
    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }
}

// textarea{
//   &.ng-invalid{
//     &.ng-dirty{
//       border-color: #ff3547;
//     }
//   }
// }

// .emailSecondError {
//   position: relative;
//   top: 35px;
// }

// .emailValidation {
//   &.hasSecondError {
//     top: 40px;

//     +.emailSecondError {
//       top: 45px;
//     }
//   }
// }
