#summary {
  .badge {
    width: auto;
    height: auto;
    border: 1px solid #333;
    border-radius: 10px;
    padding: 2rem 4rem;
    margin-bottom: 1rem;
    font-size: 1rem;

  }

  form {
    margin-bottom: 1rem;
  }

  .emptybody{
    margin: 0.4rem 0;
    background-color: $brand-primary !important;  
    .card-title{
      margin:0px;
      height:100% !important;          
    }
  }

  .card {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;

    

    .card-title {
      width: 100%;
      height: auto;
      color: #fff;
      padding: .6rem 1.25rem;
      border-radius: 4px 4px 0px 0px;
      background-color: $brand-primary !important;

      .summaryHeaderAndPrint {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h1 {
          margin-bottom: 0;
        }
      }
    }

    .card-body {
      h2 {
        font-size: 1.4rem;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 16px 24px;
  }

  .edit-link {
    float: right;
    font-size: 0.8em;
    /*text-decoration: none;*/

  }

  section {
    border: none !important;

    &.border {
      border: 1px solid #ddd !important;
    }

    &.addMarginBottom {
      margin-bottom: 25px;
    }
  }

  a {
    text-decoration: none !important;

    &.responsibilityLink {
      color: $brand-primary !important;
      text-decoration: underline !important;

      &:hover {
        color: $brand-primary-lighter !important;
        text-decoration: none !important;
      }
    }

    &.print {
      margin: -5px 0px 0px 10px;
      height: 10px;
      width: 10px;
      font-size: 1.5em;

      i.fa.fa-print {
        width: 10px;
        height: auto;
        padding: 0;
        margin: 0;
        float: right;
        clear: none;
        left: 25px;
        position: relative;
      }
    }

    &.printcomplete {
      margin: 0 55px 0 0;
      font-size: 1em;
      height: 10px;
      width: 10px;

      @media all and (width: 360px) {
        position: relative;
        bottom: 27px;
        left: 13px;
      }

      i.fa.fa-print {
        width: 10px;
        height: auto;
        padding: 0;
        margin: 0;
        float: right;
        clear: none;
        left: 25px;
        position: relative;
      }
    }
  }

  .mat-expansion-panel {
    margin-bottom: 1rem;

    .mat-expansion-panel-body {

      app-badge,
      app-summary-display,
      appaddresssummary,
      app-phone-summary,
      .ng-star-inserted,
      .guestConsiderations,
      .mainAttendeeConsiderations {

        .summaryHeader {
          font-size: 1.2rem;
          margin: 1rem 0;
        }

        .form-summary {
          padding: 1rem 1rem;
          background: #efefef;
          margin-bottom: .5rem;
          border-bottom: 1px solid #efefef;
          border-radius: .5rem;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .form-summary {
        padding: 1rem 1rem;
        background: #efefef;
        margin-bottom: .5rem;
        border-bottom: 1px solid #efefef;
        border-radius: .5rem;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  uib-accordion {
    h2 {
      position: relative;
      left: 5px;

      &.notCollapsable {
        left: 0;
      }

      &.active {
        &:before {
          content: '>';
          font-style: normal;
          text-decoration: inherit;
          color: #444;
          font-size: 18px;
          position: absolute;
          top: 10px;
          left: -10px;
        }
      }

      &.notActive {
        &:before {
          content: '^';
          font-style: normal;
          text-decoration: inherit;
          color: #444;
          font-size: 18px;
          position: absolute;
          top: 10px;
          left: -10px;
          margin-right: 10px;
        }
      }
    }
  }

  label {
    white-space: normal !important;
  }

  .nametag {
    border-radius: 30px;
    border-width: 2px;
    border-style: solid;
    display: block;
    margin: 20px;
    padding: 50px 30px 30px;
    background-color: white;
    color: black;
    max-width: 80%;
    width: 25em;
    height: 12em;
    text-align: center;

    div {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .ownair-summary {
    .ownair {
      cursor: auto;

      &:hover {
        transition: none;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .participant-summary {
    @extend .form !optional;
    @extend .well !optional;
  }

  h2 {
    &.addPaddingRight {
      padding-right: 50px;
    }
  }

  textarea {
    margin-bottom: 10px;
  }

  .form-summary {
    overflow: hidden;

    label {
      float: left;
      padding: 5px 0;
      margin: 0;
      font-weight: bold;
    }

    .form-summary-item {
      position: relative;
      float: left;
      padding: 5px 0;
      width: 100%;

      .form-summary-line {
        width: 100%;
        display: block;
      }
    }
  }

  .pIconH1 {
    @media all and (width: 360px) {
      font-size: 1.85em;
    }
  }

  .mat-expansion-panel-header-title {
    display: block;
  }

  .summaryLinks {
    .agreeCheckbox {
      margin-bottom: .5rem;
    }

    p {
      a {
        color: $ConsistentLinkColor !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: $ConsistentLinkColorHover !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([href]) {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([tabindex]) {
            color: $ConsistentLinkColor !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: $ConsistentLinkColorHover !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }
          }
        }
      }

      u {
        text-decoration: none !important;

        a {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }

          &:not([href]) {
            color: $ConsistentLinkColor !important;
            text-decoration: underline !important;
            font-weight: bold !important;

            &:hover {
              color: $ConsistentLinkColorHover !important;
              text-decoration: none !important;
              font-weight: bold !important;
            }

            &:not([tabindex]) {
              color: $ConsistentLinkColor !important;
              text-decoration: underline !important;
              font-weight: bold !important;

              &:hover {
                color: $ConsistentLinkColorHover !important;
                text-decoration: none !important;
                font-weight: bold !important;
              }
            }
          }
        }
      }
    }

    a {
      color: $ConsistentLinkColor !important;
      text-decoration: underline !important;
      font-weight: bold !important;

      &:hover {
        color: $ConsistentLinkColorHover !important;
        text-decoration: none !important;
        font-weight: bold !important;
      }

      &:not([href]) {
        color: $ConsistentLinkColor !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: $ConsistentLinkColorHover !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([tabindex]) {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }
        }
      }
    }
  }

  .checkboxContainer {
    a {
      color: $ConsistentLinkColor !important;
      text-decoration: underline !important;
      font-weight: bold !important;

      &:hover {
        color: $ConsistentLinkColorHover !important;
        text-decoration: none !important;
        font-weight: bold !important;
      }

      &:not([href]) {
        color: $ConsistentLinkColor !important;
        text-decoration: underline !important;
        font-weight: bold !important;

        &:hover {
          color: $ConsistentLinkColorHover !important;
          text-decoration: none !important;
          font-weight: bold !important;
        }

        &:not([tabindex]) {
          color: $ConsistentLinkColor !important;
          text-decoration: underline !important;
          font-weight: bold !important;

          &:hover {
            color: $ConsistentLinkColorHover !important;
            text-decoration: none !important;
            font-weight: bold !important;
          }
        }
      }
    }
  }
}
