html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

b,
strong {
  font-weight: bold !important;
}

.mailwarning {
  strong {
    color: #900;
  }
}

section {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.page {
  padding-top: 2rem;

  @media (max-width: 640px) {
    padding-top: 1rem;
  }
}

.pageTemplateContainer {
  width: 100%;
  height: auto;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    //margin: 0 -15px;
  }
}

.mat-expansion-panel {

  .mat-expansion-panel-header {
    background-color: #081c4e !important;
    border-radius: 0;
    font: 400 1rem/1.4rem Roboto, "Helvetica Neue", sans-serif !important;

    .mat-expansion-panel-header-title {
      color: #fff !important;
    }

    .mat-expansion-indicator::after {
      color: #fff !important;
    }
  }

  .mat-expansion-panel-content {
    font: 400 1rem/1.4rem Roboto, "Helvetica Neue", sans-serif !important;
  }
}

.contentFullVerticalHeight {
  min-height: 90vh;
  height: auto;
  max-height: 100%;
  width: 100%;

  &.halfVerticalHeight {
    min-height: 50vh;
  }
}

//iOS fix where labels don't appear on load
//Update 7-6-22 A newer iOS release removed the need for this fix, but wanting to keep it in if a similar problem occurs
//.mat-form-field-appearance-legacy {
//  .mat-form-field-label {
    //transform: perspective(0);
//  }
//}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: #0000008a;
}

.guestError {
  .mat-expansion-panel-header {
    background: #AA4A44 !important;
  }
}

.agreeCheckbox {
  width: 100%;
  height: auto;

  &.checkboxList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .checkboxFlexContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      margin-right: .25rem;
      line-height: 1rem;
      width: 100%;
      min-width: 100%;

      .mat-checkbox-inner-container {
        margin-left: 0 !important;
      }
    }

    .mat-checkbox {
      margin-right: .25rem;
      padding-right: 0 !important;
    }

    a {
      text-decoration: none !important;
    }
  }
}

.agreeCheckboxPac {
  width: 100%;
  height: auto;

  &.checkboxList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .checkboxFlexContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      margin-right: .25rem;
      line-height: 1.5rem;
      width: 100%;
      min-width: 100%;

      .mat-checkbox-inner-container {
        margin-left: 0 !important;
      }
    }

    .mat-checkbox {
      margin-right: .25rem;
      padding-right: 0 !important;
      line-height: 1rem;
      align-self: self-start;
      margin-top: 3px;
    }

    a {
      text-decoration: none !important;
    }
  }
}

.checkboxFlexContainer {
  display: flex;

  &.checkboxList {
    flex-direction: column;
  }

  label {
    margin-right: .25rem;
    line-height: 1rem;
    width: 100%;
    min-width: 100%;

    .mat-checkbox-inner-container {
      margin-left: 0 !important;
    }
  }

  .questionLabel {
    margin-bottom: 1rem;
  }

  .mat-checkbox {
    margin-right: .25rem;
    padding-right: 0 !important;
  }

  a {
    text-decoration: none !important;
  }
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: .6rem;
  }
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    margin-bottom: .6rem;
  }
}

#outer_container {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;

  .program {
    border: 1px solid #fbc81b;
    padding: 20px;
    margin-bottom: 2rem;
    position: relative;

    .printButton {
      position: absolute;
      top: 2%;
      right: 2%;

      .mat-icon {
        margin-bottom: 0 !important;
      }
    }
  }

  .mat-grid-list {
    @media (max-width: 640px) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: auto !important;
    }

    div {
      @media (max-width: 640px) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
      }

      .mat-grid-tile {
        @media (max-width: 640px) {
          width: 32% !important;
          margin-right: 1rem;
          position: relative;
          top: 0 !important;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .card {
    margin-bottom: 2rem;
    width: 100%;

    .card-header {
      background: #081c4e !important;
      color: #fff !important;
      font-size: 1.2rem !important;
      width: 100%;

      .card-title {
        margin-bottom: 0 !important;
        display: flex;
        align-items: flex-start;
        margin-left: 1rem;

        i {
          font-size: .4rem;
        }
      }
    }

    .card-body {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: auto;

      @media (max-width: 640px) {
        padding: 1.25rem .75rem;
      }

      label {
        white-space: normal;

        .addressLabel {
          margin-bottom: 1rem;
        }
      }
    }
  }

  article {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    height: auto;

    @media (max-width: 640px) {
      padding: 10px 0px;

    }
  }
}

.help-block {
  margin-bottom: 1rem;
}

.btn {

  &.btn-primary, &.btn-secondary {
    background: $brand-alt !important;
    border: 1px solid #fff !important;
    color: #fff;

    &:hover {
      background: $brand-alt-darker !important;
      border-color: $brand-alt-darker !important;
      //color: $brand-primary!important;

    }
  }

  &.btn-outline-primary {
    border-color: $brand-primary;
    color: $brand-primary;

    &:hover {
      background: $brand-primary-lighter !important;
      border-color: $brand-primary-lighter !important;
      color: $brand-white;
    }
  }
}

.button_group {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;

  @media (max-width: 640px) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .btn {

    @media (max-width: 640px) {
      margin-bottom: 1rem;
    }

    &.closeButton {
      border-radius: 50% !important;
    }
  }

  &.profileContinue {
    padding-left: 0 !important;
  }

  &.profileSelect {
    padding-left: 0 !important;
  }

  &.profileConfirm {
    padding-left: 0 !important;
  }
}


.multiSelect {
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: 640px) {
    flex-direction: column;
  }

  mat-form-field {
    width: 30%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }
}

.mat-form-field {
  &.twoLineSelect {
    .mat-select-placeholder {
      @media (max-width: 640px) {
        overflow: visible !important;
        margin-top: 40px;
      }
    }
  }

  &.longQuestionText {

    input[type=text] {
      @media (max-width: 640px) {
        overflow: visible !important;
        margin-top: 30px;
      }
    }

    input[type=number] {
      @media (max-width: 640px) {
        overflow: visible !important;
        margin-top: 30px;
      }
    }

    textarea {
      @media (max-width: 640px) {
        overflow: visible !important;
        margin-top: 30px;
      }
    }
  }
}

app-date {
  width: 100%;

  .mat-form-field-infix {
    width: 100%;
  }
}

.mat-form-field {
  .mat-form-field-label {
    font-size: 1rem;
  }

  &.mat-focused {
    .mat-form-field-ripple {
      background-color: #4285f4;
    }

    .mat-form-field-label {
      color: #4285f4;
    }
  }
}

mat-radio-group {
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: flex-start;
  align-items: flex-start;

  mat-radio-button {
    &.mat-radio-button {

      @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
        width: 100%;
      }

      &.mat-accent {
        &.mat-radio-checked {
          .mat-radio-inner-circle {
            background-color: $brand-primary !important;
          }

          .mat-radio-outer-circle {
            border-color: $brand-primary;
          }
        }
      }
    }
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $brand-primary !important;
}

.mat-radio-label-content,
.mat-checkbox-label {
  white-space: normal;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  overflow: hidden;
}

.stickyButton {
  position: fixed;
  top: 15%;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;

  .mat-raised-button {
    background-color: #fbc81b;
  }
}

.text-white {
  color: #fff;
}

.fa-info-circle {
  color: #081c4e !important;
}