.hotelTable {
  font-family: 'Noto Sans JP', sans-serif !important;

  thead {

    th {
      //background-color: #ddd !important;
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#555), to(#999)) !important;
      // background-image: linear-gradient(180deg, #555, #999) !important;
      color: #555;
      text-align: center;
      font-weight: 400;
      font-size: 1.3rem;
      border: 0px solid transparent !important;
      padding: 1rem .5rem;

      &:first-child {
        background: #fff !important;
      }

      &.eventDates {
        background-color: #efefef !important;
        border-left: 2px solid #999 !important;
        border-right: 2px solid #999 !important;
        border-top: 2px solid #999 !important;
        color: #333;
        font-weight: 600;
        // background-image: -webkit-gradient(linear, left top, left bottom, from(#999), to(#777)) !important;
        // background-image: linear-gradient(180deg, #999, #7777) !important;
      }

      &.extension {
        background-color: #d4d4d4 !important;
        border-left: 2px solid #999 !important;
        border-right: 2px solid #999 !important;
        border-top: 2px solid #999 !important;

      }
    }
  }

  tbody {
    width: 100%;
    height: 100px;
    border-top: none;

    tr {

      th {
        background: #045ba8;
        color: #fff;
        //width: 45px;
        min-width: 60px !important;
        max-width: 60px !important;
        text-align: center;
        border-right: 1px solid #777;
        font-size: 1.2rem;
        font-weight: 300;
        padding: .5rem;

        &:first-child {
          background: #fff;
          min-width: 160px !important;
          color: #333;
          border-top: 1px solid transparent;
          vertical-align: middle;
          font-weight: bold;
          text-align: left;
          text-transform: capitalize;
        }

        &.hotelDetails {
          h2 {
            font-size: 1.4rem;
            font-weight: 500;
          }

          p {
            a {
              font-size: 1rem;
              color: $ConsistentLinkColor;
              font-weight: 400;
              text-decoration: underline;
              display: none!important;

              &:hover {
                color: $ConsistentLinkColorHover;
                text-decoration: none;
              }
            }
          }
        }

        span {
          &.date {
            width: 100%;
            height: auto;
            float: left;
            clear: left;
            margin-bottom: 0px;
            font-size: .8em;
            font-weight: 400;
            font-family: 'Roboto Condensed', sans-serif !important;
          }

          &.day {
            width: 100%;
            height: auto;
            font-weight: bold;
            font-family: 'Roboto Condensed', sans-serif !important;
            float: left;
            clear: left;
            font-size: 1.2em;
            margin-bottom: -.5rem;
            //text-transform: uppercase;
          }
        }
      }

      td {
        &.prepost{
          background-color: #d4d4d4 ;
        }

        border-top: none;
        border-right: 1px solid #777;
        border-bottom: 1px solid #777;
        min-width: 45px;
        max-width: 45px;
        height: 70px;
        background: #ebebeb;

        &:first-child {
          background: #fff;
        }

        .hotel_desc {
          font-size: .8rem;
          font-weight: bold;
          font-family: 'Noto Sans JP', sans-serif !important;
        }
      }
    }

    +tbody {
      border-top: none;
    }
  }
}
