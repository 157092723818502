.footer-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;


  img{
    max-width: 1124px;
  }

  .footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #000;
    min-height: 100px;
    text-align: center;

    .footer-items{
      margin-bottom: 0.8rem;
    }

    .material-icons{
      transform: scale(0.7);
    }

    a {
      color: #fff;
      margin-right: 1rem;
      cursor: pointer;
      text-decoration: none;
      &:last-child {
        margin-right: 0;
      }

      &:hover{
          transition: all .2s ease-in-out;
          color: khaki;
          .svg-fill{
            transition: all .2s ease-in-out;
            fill: khaki;
          }
      }
    }

    .mtlogo, svg{
      width: 100%;
      max-width: 100px;
      height: 2rem;
    }

    .maritz-url{
      display: flex;
      flex-direction: row;
      align-items: center;

      a{
        display: flex;
        align-items: center;
      }
    }

    .svg-fill{
      fill: #ffffff;
      transition: all .2s ease-in-out;
    }
  }
}