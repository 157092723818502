@import '../../app.variables.scss';

#login {

  a {
    color: #0069d9;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .content {
    //margin-top: 25px;
  }

  .loginFlexContainer {
    flex-direction: row;

    @media all and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      width: 100%;
    }

    @media (max-width: 991px) {
      flex-direction: column;
    }

    .login-info {
      width: 48%;
      margin-bottom: 1rem;
      padding-right: 1rem;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .login-forms {
      width: 48%;
      margin-bottom: 1rem;

      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }


  .panel {
    @extend .panel;

    .panel-heading {
      background: $brand-secondary;
      color: #fff;

      .panel-title {
        font-weight: 200;
      }
    }

    .panel-body {
      .form-group i {
        font-size: 1.5rem;
        color: $brand-primary;
      }
    }
  }

  .button_group {
    width: 100%;
    /*flex-direction: row-reverse;*/
    justify-content: space-between;

    @media (max-width: 640px) {
      flex-direction: column;
      justify-content: center;
    }

    .inputSubmit {

      @media (max-width: 480px) {
        //float: left;
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .back {
      @media (max-width: 480px) {
        width: 100%;
      }
    }

    input[type="submit"] {

      &.forward {

        @media (max-width: 480px) {
          width: 100%;
          margin-bottom: 1rem;
        }

        &:hover {
          background: $brand-success;
        }
      }

      &.back {}
    }
  }

  .helpiconInTextbox{
    float: right;
    position: absolute;
    left: 97%;
  }
}
